import { useEffect, Fragment } from "react";
import { useParams, NavLink } from "react-router-dom";
import "./styles_drawingScreen.scss";
import { motion } from "framer-motion";

import Aos from "aos";
import "aos/dist/aos.css";
//---------------
import allData__Meyoko from "../data__AllMeyoko";
//---------------
import { BouncingSoftBtn, Btn3dEffect } from "../components";
import {
  FixedStickyCompImg,
  ImgLgCardTar,
  SvgStarStroke,
} from "../componentHome";

import imgBgLand from "../assets/icons/landscape.svg";

//
//
const DrawingScreen = () => {
  //
  //
  // ------ aos
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  // ------ aos

  const { id } = useParams();
  const product = allData__Meyoko.find((p) => p._id === id);

  if (!product) {
    return <div>Drawing not found!</div>;
  }

  return (
    <section
      id="wrapper-drawingScreen"
      style={{
        "--bg-color": product.backgroundColor,
        "--color-border": product.backgroundColor2,
      }}
    >
      <ImgLgCardTar
        titleTopGallery={product.title}
        //
        imgTaroMiddle={product.imgTopUnframed}
        titleEnter="Enter"
        //
        symbolLeftBottomDescript="&#x2726;"
        symbolMiddleBottomDescript={product.smDescriptionAboutCollection}
      />
      {/*  */}
      <FixedStickyCompImg product={product} />
      {/*


      */}

      {product.descDraw1 && (
        <>
          <div className="wrapper-grid-TextDrawScreen">
            <div
              className="container-TextDrawScreen"
              style={{
                "--bg-color-2": product.backgroundColor2,
              }}
            >
              <div className="left_TextDrawScreen">
                {/* 🌟 */}
                <div
                  className="container-star"
                  style={{
                    backgroundRepeat: "no-repeat",
                    backgroundImage: `url(${imgBgLand})`,
                    backgroundAttachment: "fixed",
                    backgroundSize: "cover",
                    backgroundPosition: " center right",
                  }}
                >
                  <div className="circle-box">
                    <SvgStarStroke />
                  </div>
                </div>
              </div>
              {/* IMG  */}
              <div className="wrapper-TextDrawScreen">
                <div
                  className="container-TextDrawScreen"
                  style={{
                    backgroundRepeat: "no-repeat",
                    backgroundImage: `url(${imgBgLand})`,
                    backgroundAttachment: "fixed",
                    backgroundSize: "cover",
                    backgroundPosition: "center left",
                  }}
                >
                  <h1
                    data-aos="fade-down"
                    data-aos-easing="linear"
                    data-aos-duration="700"
                  >
                    {" "}
                    {product.descDraw1 ? <h1>{product.descDraw1}</h1> : ""}
                  </h1>

                  <p
                    data-aos="fade-down"
                    data-aos-easing="linear"
                    data-aos-duration="1400"
                  >
                    {product.descDraw2 ? <p>{product.descDraw2}</p> : ""}
                  </p>

                  <p
                    data-aos="fade-down"
                    data-aos-easing="linear"
                    data-aos-duration="2000"
                  >
                    {" "}
                    {product.descDraw3 ? <p>{product.descDraw3}</p> : ""}
                  </p>
                </div>
              </div>
              <div className="right_TextDrawScreen">
                <motion.div
                  className="container-star"
                  style={{
                    backgroundRepeat: "no-repeat",
                    backgroundImage: `url(${imgBgLand})`,
                    backgroundAttachment: "fixed",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                >
                  <motion.div className="circle-box">
                    <SvgStarStroke />
                  </motion.div>
                </motion.div>
              </div>
            </div>
          </div>
        </>
      )}

      {/*


       */}
      {product.category === "home-screen-gallery-mementoMori" ? (
        <>
          <div id="gallery_page">
            <div className="gallery_page-container">
              <div className="gallery-card-wrapper">
                {allData__Meyoko
                  .filter(
                    (product) =>
                      product.category === "home-screen-gallery-mementoMori"
                  )
                  .map((item, e) => (
                    <div className="gallery_page-card" key={item.id}>
                      <motion.div className="gallery-page-container">
                        {item.coverImg ? (
                          <div
                            className="wrapper-video-and-img"
                            style={{
                              backgroundRepeat: "no-repeat",
                              backgroundImage: `url(${imgBgLand})`,
                              backgroundAttachment: "fixed",
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                            }}
                          >
                            <NavLink
                              to={`/folio-drawing/${item._id}/${item.extraUrlName}`}
                            >
                              <div className="video-img-container">
                                <BouncingSoftBtn>
                                  <motion.img
                                    className="video-img_box"
                                    src={item.coverImgTransp}
                                    alt={item.altDrawingScreen}
                                  />
                                </BouncingSoftBtn>
                              </div>
                            </NavLink>
                          </div>
                        ) : (
                          ""
                        )}
                      </motion.div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </>
      ) : product.category === "home-screen-gallery-the-furies" ? (
        <>
          <div id="gallery_page">
            <div className="gallery_page-container">
              <div className="gallery-card-wrapper">
                {allData__Meyoko
                  .filter(
                    (product) =>
                      product.category === "home-screen-gallery-the-furies"
                  )
                  .map((item, e) => (
                    <div className="gallery_page-card" key={item.id}>
                      <motion.div className="gallery-page-container">
                        {item.coverImg ? (
                          <div
                            className="wrapper-video-and-img"
                            style={{
                              backgroundRepeat: "no-repeat",
                              backgroundImage: `url(${imgBgLand})`,
                              backgroundAttachment: "fixed",
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                            }}
                          >
                            <NavLink
                              to={`/folio-drawing/${item._id}/${item.extraUrlName}`}
                            >
                              <div className="video-img-container">
                                <BouncingSoftBtn>
                                  <motion.img
                                    className="video-img_box"
                                    src={item.coverImgTransp}
                                    alt={item.altDrawingScreen}
                                  />
                                </BouncingSoftBtn>
                              </div>
                            </NavLink>
                          </div>
                        ) : (
                          ""
                        )}
                      </motion.div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      <Btn3dEffect textButton3d="Return" pathUrlWithin="/prints-collections" />
    </section>
  );
};

export default DrawingScreen;
