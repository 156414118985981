import { useLocation, NavLink } from "react-router-dom";
// DATA
import allMeyoko from "../../data__AllMeyoko.js";
//  ----- FRAMER
import { motion } from "framer-motion";
import ScaleInTransitionPage from "../AnimationProps/ScaleIN-transition-enter-page.jsx";
//  ------ FRAMER
import { HeadlinePatterOpt2 } from "../../headlineComponents/index.js";
//
// ** IMGS
import ImgMiddleGalleryHeadline from "../../assets/img_drawings/nephentes_grand_meyoko_lowRe.jpg";
import { DividersSm } from "../index.js";
//  ** STYLES
import "./gallery-item-lg.scss";
//
//
//
//
const Index = () => {
  //

  const location = useLocation();

  return (
    <>
      <div id="gallery-wrapper-lg">
        {location.pathname === "/folio" ? (
          <>
            <div className="wrapper-grid-headlineGallery">
              <div className="container-grid-headlineGallery">
                {/* left */}
                <div className="grid-headlineGallery_left">
                  {/* <HeadlinePatterOpt2
                    titlePatt="&#x2724;"
                    bgColorSymbol="transparent"
                    colorSymbol="#cfd3d8"
                  /> */}
                </div>
                {/* 🌽 IMG middle */}
                <div className="grid-headlineGallery_middle">
                  <div className="wrapper-img_headlineGallery">
                    <div className="container-img_headlineGallery">
                      <ScaleInTransitionPage>
                        <motion.img
                          src={ImgMiddleGalleryHeadline}
                          alt="img middle Nephentes by meyoko"
                          whileHover={{
                            scale: 1.1,
                            transition: {
                              type: "spring",
                              duration: 1.6,
                              damping: 33,
                              delay: 0.08,
                            },
                          }}
                        />
                      </ScaleInTransitionPage>
                    </div>
                  </div>
                </div>
                {/* right */}
                <div className="grid-headlineGallery_right">
                  {/* <HeadlinePatterOpt2
                    titlePatt="&#x2724;"
                    bgColorSymbol="transparent"
                    colorSymbol="#cfd3d8"
                  /> */}{" "}
                </div>
              </div>
            </div>
            <DividersSm />

            <HeadlinePatterOpt2
              // titlePatt="&#x2724;"
              colorSymbol="#111111"
              subTitlePatt1="The"
              subTitlePatt2="Selection"
            />

            {/* the gallery */}
            <div className="gallery-container-lg">
              <div className="gallery-lg-card-container">
                {allMeyoko
                  .filter(
                    (product) => product.collection === "allDrawingsFolio"
                  )
                  .map((itemFolio) => (
                    <ul key={`${itemFolio._id}`} className="card-gallery-lg">
                      <li className="img-container-gallery-lg">
                        <NavLink
                          style={{ cursor: "none" }}
                          to={`/folio-drawing/${itemFolio._id}/${itemFolio.extraUrlName}`}
                        >
                          <div className="img-box-gallery-lg">
                            <ScaleInTransitionPage>
                              <motion.img
                                src={itemFolio.imgTopUnframed}
                                alt={itemFolio.altDrawingScreen}
                                whileHover={{
                                  scale: 1.03,
                                  transition: {
                                    type: "spring",
                                    duration: 1,
                                    damping: 23,
                                    delay: 0.08,
                                  },
                                }}
                              />
                            </ScaleInTransitionPage>
                          </div>
                        </NavLink>
                      </li>
                    </ul>
                  ))}
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default Index;
