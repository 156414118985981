import React from "react";
import { SvgStarStroke } from "..";
import "./styles-imgLgCardTar.scss";

import { NavLink, useLocation } from "react-router-dom";

import { motion, useMotionValue, useTransform } from "framer-motion";
import {
  BouncingBtn,
  BouncingSoftBtn,
  ScaleInTransition,
} from "../../components";

const Index = ({
  imgTaroMiddle,
  titleTopGallery,
  symbolMiddleBottomDescript,
  //
  redirectPath,
}) => {
  //
  //

  const x = useMotionValue(0);
  const y = useMotionValue(0);
  const rotateX = useTransform(y, [-100, 100], [30, -30]);
  const rotateY = useTransform(x, [-100, 100], [-30, 30]);

  const location = useLocation();
  //
  //
  return (
    <section id="wrapper-imgTaro">
      {titleTopGallery && (
        <div className="headlineH1Box-Bouncing">
          <BouncingSoftBtn>
            <h1 className="headlineh1_Taro">.{titleTopGallery}.</h1>
          </BouncingSoftBtn>
        </div>
      )}

      <div className="container-imgTaro">
        {/* gird img on the middle */}
        <div className="wrapper-grid-imgTaro">
          <div className="container-grid-imgTaro">
            <div className="left_imgTaro">
              {/* 🌟 */}
              <div className="container-star">
                <div className="overlay-left-imgTar">
                  <SvgStarStroke />
                </div>
              </div>
            </div>

            <div className="wrapper-imgBox-imgTar">
              {/* svg */}

              <div className="container-imgBox-imgTar">
                {location.pathname === "/" ? (
                  <>
                    <div className="overlay-middle-imgTar">
                      <BouncingBtn>
                        {" "}
                        <NavLink to={redirectPath}>
                          <button> Enter</button>
                        </NavLink>
                      </BouncingBtn>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="overlay-middle-imgTar">
                      <BouncingBtn>
                        {" "}
                        <p>Soon</p>
                      </BouncingBtn>
                    </div>
                  </>
                )}

                <motion.div className="imgBox-imgTar">
                  {/*

                  IMG 🟧

                  */}
                  <ScaleInTransition>
                    <motion.img
                      src={imgTaroMiddle}
                      alt=""
                      style={{ x, y, rotateX, rotateY, z: 100 }}
                      drag
                      dragElastic={0.16}
                      dragConstraints={{
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                      }}
                    />
                  </ScaleInTransition>
                </motion.div>
              </div>
            </div>
            <div className="right_imgTaro">
              <div className="container-star">
                <div className="overlay-right-imgTar">
                  {/* 🌟 */} <SvgStarStroke />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*


         TEXT 3  🟧

         */}

        <div className="wrapper-grid-imgLgCardTar-text">
          <div className="container-imgLgCardTar-text">
            <div className="left_imgLgCardTar-text">
              <h1>&#x2726;</h1>
            </div>
            <div className="middle_imgLgCardTar-text">
              <p> {symbolMiddleBottomDescript}</p>
            </div>

            <div className="right_imgLgCardTar-text">
              <h1>&#x2726;</h1>
            </div>
          </div>
        </div>
        {/*  */}
      </div>
    </section>
  );
};

export default Index;
