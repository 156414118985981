import React from "react";
import { useLocation } from "react-router-dom";

import "./styles-headlineLg.scss";

const Index = ({ titleHeadlineWelcH1, subTitleHeadlineWelcH2 }) => {
  //
  //
  const location = useLocation();
  //
  //
  return (
    <>
      {location.pathname === "/" ? (
        <>
          <div className="wrapper-HeadlineLg">
            <div className="container-HeadlineLg">
              <h1>Welcome.</h1>
              <h2>To Meyoko</h2>
            </div>
          </div>
        </>
      ) : (
        <div className="wrapper-HeadlineLg_textScreens">
          <div className="container-HeadlineLg_textScreens">
            <h1 className="HeadlineLg_textScreensH1">{titleHeadlineWelcH1}.</h1>
            <h2 className="HeadlineLg_textScreensH2">
              "{subTitleHeadlineWelcH2}"
            </h2>
          </div>
        </div>
      )}
    </>
  );
};

export default Index;

/*







<h1>{titleHeadlineWelcH1}.</h1>
        <h2> To "{subTitleHeadlineWelcH2}"</h2>

*/
