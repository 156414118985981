import React from "react";

import banFlor from "../../assets/bio/bonhomme.gif";
import { NephentesDetail } from "../../headlineComponents/indexImg.js";
import { DividersSm, FooterPrintsSvg } from "../../components/index.js";
import { ArchComponent } from "../../headlineComponents/index.js";

import "./styles-allPrints.scss";
import { GalleryAllDrawingsFolio } from "../../componentHome";

const Index = () => {
  return (
    <section id="all-prints">
      <ArchComponent
        titlePatt="Folio"
        subTitlePatt1="Enjoy"
        subTitlePatt2="The Selection"
        imgArch={banFlor}
        imgArchBg={NephentesDetail}
        h1MiddleTextLeft="Ethereal grace, gentle kindness, serenity"
        h2MiddleTextRight=" Every unfolding moment fuels my creativity"
        h1TitleArchDesc="The Initiation"
        h2TitleArchDesc="2008"
        spanTitleArchDesc="| 2024"
        spanArchDesc1="Embark on a journey through Meyoko's world"
        pTitleArchDesc="of art with her personally curated selection of prints."
        spanArchDesc2="Dive into the captivating realm of Art Nouveau, where intricate designs and organic forms"
        pTitleArchDesc1="merge seamlessly. Whether it's the timeless allure of black and white compositions or the vibrant energy of watercolor accents"
        spanArchDesc3="each print"
        pTitleArchDesc2="reflects Meyoko's distinctive style honed over"
        spanArchDesc4="fifteen years of artistic."
        pTitleArchDesc3="exploration and refinement"
      />
      <GalleryAllDrawingsFolio />
      <DividersSm />
      <FooterPrintsSvg />
    </section>
  );
};

export default Index;
