const dataAllMeyoko = [
  //
  // -----------------
  //  ** THE FURIES
  // -----------------
  //
  {
    _id: "0",
    extraUrlName: "alecto",
    title: "Alecto",
    subTitle: "The | Furies",
    spanTitle: "collection",
    artCreationDate: "2012",
    // ** DIMENSIONS & MATERIALS
    height: 59,
    width: 42,
    materials: "ink, acrylic, ballpoint pen, collage",
    // ** DESC (p)
    descDraw1:
      "Meyoko's creation of this triptych of 'the Furies' was spurred not by personal anger, but by a deep fascination with the myth of Alecto, one of the Furies in Greek mythology. Alecto, symbolizing eternal anger and vengeance, became a focal point for the artist's exploration.",
    descDraw2:
      "While crafting the series, the artist was deeply affected by the plight of women in Ciudad Juarez, particularly the cases of the missing women. This harrowing reality influenced the artist's decision to delve into the myth of the Furies, as one of them is said to seek justice for victims of egregious offenses.",
    descDraw3:
      "Incorporating themes of justice and retribution, the artist's interpretation of the myth serves as a poignant commentary on the ongoing struggles faced by women in Ciudad Juarez and beyond. Through the lens of ancient mythology, the artist sheds light on contemporary issues of violence against women, seeking to evoke empathy and provoke reflection on the urgent need for change.",
    // ** IMAGES
    coverImg: "/assets/The-furies/AlectoByMeyoko__frme.jpg",
    // ** --- cover for the gallery
    coverImgTransp: "/assets/The-furies/alecto-web_meyoko_-trans.png",
    // ** --- img Top ---
    // ** not framed drawing
    imgTopUnframed: "/assets/The-furies/alecto-web_meyoko_lowre.jpg",
    //
    //
    imgDraw1: "/assets/The-furies/alecto-frame-c_00.jpg",
    imgDraw2: "/assets/The-furies/thefuries_c_tryptique.jpg",
    // imgDraw3: "",
    // imgDraw4: "",
    // ** LG img
    imgDraw5Large: "/assets/The-furies/15_alecto.jpg",
    imgFixedBgDrawingScreen: "/assets/The-furies/thefuries_c_tryptique.jpg",
    altDrawingScreen:
      "images of meyoko's Furies Collection, which include: alecto, megaera, tisiphone",

    //
    //  ---- LINK to shop
    urlToProduct: "https://meyoko-creative.company.site/products/alecto",
    urlToShop: "https://meyoko-creative.company.site/",
    //  ---- COLLECTION
    collection: "allDrawingsFolio",
    //
    // ** -- The FURIES farbe --
    //       Title color
    headlineTitleLgTop: "#111111",
    svgSymbolColor: "#111111",
    //-----------
    //        BG & font 🔶
    backgroundColor: "#fff0c0", // yellow
    // #f9d3c72f  BG powder pink, this color has a transparency that mixed with the yellow bg of the MAIN, creates a nice shade of pink
    backgroundColor2: "#fff0c0", // yellow
    lgTitle: "#111111",
    smTitle: "#111111",
    paragraphDescription: "#0c0c0c",
    // -----------
    // -----------
    // BTN'S colors
    bgButton: "#0d0d0d",
    bgHoverButton: "transparent",
    colorFontButton: "#000000",
    colorHoverFontButton: "#f1e5cfd6",
    // btn small icon (attached to the btn)
    backgroundSMBtnAttached: "#fdfbf3",
    //
    // **  CATEGORIES
    categoryFolioGallery: "folio-picked-drawings",
    // **  CATEGORIES
    // all press
    brand: "allDrawings",
    category: "home-screen-gallery-the-furies",
    // category: "drawings",
    // brand: "alecto",
    allCategories: "allDrawings",
    // related posts
    relatedPost: "editorial",
    relatedPosts: [
      {
        id: 0,
        label: "editorial",
        imgRelPost: "",
      },
      {
        id: 1,
        label: "apparel",
        imgRelPost: "",
      },
    ],
    // ** TAGS 🟩
    tags: [
      {
        id: 0,
        label: "drawing",
      },
      {
        id: 1,
        label: "collage",
      },
      {
        id: 2,
        label: "thefuries",
      },
    ],
  },
  {
    _id: "1",
    extraUrlName: "tisiphone",
    title: "Tisiphone",
    subTitle: "The | Furies",
    spanTitle: "collection",
    artCreationDate: "2012",
    // ** DIMENSIONS & MATERIALS
    height: 59,
    width: 42,
    materials: "ink, acrylic, ballpoint pen, collage",
    // ** DESC (p)
    descDraw1:
      "In Athens, Meyoko found herself at a friend's house. While browsing through his library,  She stumbled upon a book about fashion. In it, She rediscovered Petrus Christus 'A Young Lady' which sparked the idea for her drawing.",
    descDraw2:
      "Tisiphone, featured in the drawing, is one of the Erinyes or Furies in Greek mythology. Known for punishing crimes such as murder, Tisiphone, along with her sisters Alecto and Megaera, embodies the relentless pursuit of justice. Legend has it that Tisiphone's role extended beyond her duties as an avenger, as she fell in love with Cithaeron. However, their love story ended tragically when Tisiphone's affection led to Cithaeron's demise by snakebite.",
    descDraw3:
      "This depiction of Tisiphone encapsulates the artist's fascination with the intertwining themes of beauty, passion, and retribution found within mythological narratives.",
    // ** IMAGES
    coverImg: "/assets/The-furies/TisiphoneByMeyoko_frame.jpg",
    // cover for the gallery
    coverImgTransp: "/assets/The-furies/Tisiphone-trans.png",
    // ** --- img Top ---
    // ** not framed drawing
    imgTopUnframed: "/assets/The-furies/Tisiphone.jpg",

    //
    imgDraw1: "/assets/The-furies/tisiphone-frame-c_00.jpg",
    imgDraw2: "/assets/The-furies/thefuries_c_tryptique.jpg",
    // imgDraw3: "",
    // imgDraw4: "",
    // ** LG img
    imgDraw5Large: "/assets/The-furies/15_tisisphone.jpg",
    imgFixedBgDrawingScreen: "/assets/The-furies/thefuries_c_tryptique.jpg",
    altDrawingScreen:
      "images of meyoko's Furies Collection, which include: alecto, megaera, tisiphone",

    //  ---- LINK to shop

    urlToProduct:
      "https://meyoko-creative.company.site/products/tisiphone-635450953",
    urlToShop: "https://meyoko-creative.company.site/",
    //
    //  ---- COLLECTION
    collection: "allDrawingsFolio",
    //
    // ** -- The FURIES farbe --
    //       Title color
    headlineTitleLgTop: "#111111",
    svgSymbolColor: "#111111",
    //-----------
    //        BG & font 🔶
    backgroundColor: "#fff0c0", // yellow
    // #f9d3c72f  BG powder pink, this color has a transparency that mixed with the yellow bg of the MAIN, creates a nice shade of pink
    backgroundColor2: "#fff0c0", // yellow
    lgTitle: "#111111",
    smTitle: "#111111",
    paragraphDescription: "#0c0c0c",
    // -----------
    // -----------
    // BTN'S colors
    bgButton: "#0d0d0d",
    bgHoverButton: "transparent",
    colorFontButton: "#000000",
    colorHoverFontButton: "#f1e5cfd6",
    // btn small icon (attached to the btn)
    backgroundSMBtnAttached: "#fdfbf3",
    //
    //

    // all press
    brand: "allDrawings",
    category: "home-screen-gallery-the-furies",
    // category: "drawings",
    // brand: "alecto",
    allCategories: "allDrawings",
    // related posts
    relatedPost: "editorial",
    relatedPosts: [
      {
        id: 0,
        label: "editorial",
        imgRelPost: "",
      },
      {
        id: 1,
        label: "apparel",
        imgRelPost: "",
      },
    ],
    // ** TAGS 🟩
    tags: [
      {
        id: 0,
        label: "drawing",
      },
      {
        id: 1,
        label: "collage",
      },
      {
        id: 2,
        label: "thefuries",
      },
    ],
  },
  {
    _id: "2",
    extraUrlName: "megaera",
    title: "Megaera",
    subTitle: "The | Furies",
    spanTitle: "collection",
    artCreationDate: "2012",
    // ** DIMENSIONS & MATERIALS
    height: 59,
    width: 42,
    materials: "ink, acrylic, ballpoint pen, collage",
    // ** DESC (p)
    descDraw1:
      "Megaera emerges from the depths of Greek mythology, a formidable figure veiled in the essence of jealousy and retribution. Known as one of the Erinyes or Furies, her name whispers tales of divine vengeance.",
    descDraw2:
      "Legend tells of Megaera's birth, born from the crimson cascade of Uranus' blood when Cronus, in his rebellion, severed the ties of sky and earth. Alongside her sisters Alecto and Tisiphone, she embodies the primal forces of justice, ensuring that the scales remain balanced even in the face of defiance.",
    descDraw3:
      "Within the artist's rendering, Megaera's presence is imbued with an aura of simmering resentment and righteous anger. Each stroke of the brush tells a story of betrayal punished and justice restored.",
    // ** IMAGES
    coverImg: "/assets/The-furies/MegaeraByMeyoko_frame.jpg",
    // cover for the gallery
    coverImgTransp: "/assets/The-furies/MEGAERA_trans.png",
    // ** --- img Top ---
    // ** not framed drawing
    imgTopUnframed: "/assets/The-furies/MEGAERA.jpg",

    //
    imgDraw1: "/assets/The-furies/megaera-frame-c_00.jpg",
    imgDraw2: "/assets/The-furies/thefuries_c_tryptique.jpg",
    // imgDraw3: "/assets/The-furies/MegaeraByMeyoko_frame.jpg",
    // imgDraw4: "",
    // ** LG img
    imgDraw5Large: "/assets/The-furies/15_megaera.jpg",
    imgFixedBgDrawingScreen: "/assets/The-furies/thefuries_c_tryptique.jpg",
    altDrawingScreen:
      "images of meyoko's Furies Collection, which include: alecto, megaera, tisiphone",

    //
    //  ---- LINK to shop

    urlToProduct: "https://meyoko-creative.company.site/products/megaera",
    urlToShop: "https://meyoko-creative.company.site/",
    //
    //  ---- COLLECTION
    collection: "allDrawingsFolio",
    //
    // ** -- The FURIES farbe --
    //       Title color
    headlineTitleLgTop: "#111111",
    svgSymbolColor: "#111111",
    //-----------
    //        BG & font 🔶
    backgroundColor: "#fff0c0", // yellow
    // #f9d3c72f  BG powder pink, this color has a transparency that mixed with the yellow bg of the MAIN, creates a nice shade of pink
    backgroundColor2: "#fff0c0", // yellow
    lgTitle: "#111111",
    smTitle: "#111111",
    paragraphDescription: "#0c0c0c",
    // -----------
    // -----------
    // BTN'S colors
    bgButton: "#0d0d0d",
    bgHoverButton: "transparent",
    colorFontButton: "#000000",
    colorHoverFontButton: "#f1e5cfd6",
    // btn small icon (attached to the btn)
    backgroundSMBtnAttached: "#fdfbf3",
    //
    //
    // **  CATEGORIES
    // all press
    brand: "allDrawings",
    category: "home-screen-gallery-the-furies",
    // category: "drawings",
    // brand: "alecto",
    allCategories: "allDrawings",
    // related posts
    relatedPost: "editorial",
    relatedPosts: [
      {
        id: 0,
        label: "editorial",
        imgRelPost: "",
      },
      {
        id: 1,
        label: "apparel",
        imgRelPost: "",
      },
    ],
    // ** TAGS 🟩
    tags: [
      {
        id: 0,
        label: "drawing",
      },
      {
        id: 1,
        label: "collage",
      },
      {
        id: 2,
        label: "thefuries",
      },
    ],
  },
  //-----------------
  //   SKULLS memento mori 💀
  //-----------------
  {
    _id: "3",
    extraUrlName: "acherontia",
    title: "Acherontia",
    subTitle: "Memento | Mori",
    spanTitle: "collection",
    artCreationDate: "2016",
    // ** DIMENSIONS & MATERIALS
    height: 59,
    width: 42,
    materials: "ink, acrylic, digital",
    // ** DESC (p)
    descDraw1:
      "Inspired by the fusion of beauty in death, as seen in 'The Most Beautiful Dead' by Koudounaris and the blending of skeletons with masks in Maison Martin Margiela's haute couture A/W12, my goal was to evoke a harmonious interplay of life and art in my portrayal.",
    // descDraw2:
    //   "Legend tells of Megaera's birth, born from the crimson cascade of Uranus' blood when Cronus, in his rebellion, severed the ties of sky and earth. Alongside her sisters Alecto and Tisiphone, she embodies the primal forces of justice, ensuring that the scales remain balanced even in the face of defiance.",
    descDraw3:
      "This series intertwines insects, marine life, plants, and mythology, exploring symbolism with a profound emphasis on death.",
    //
    // 🟠 SM extrait/desc of the collection
    smDescriptionAboutCollection:
      "Memento Mori, a Latin phrase meaning 'Remember you must die', invites contemplation on mortality—a reflection not of fear, but of life's fleeting beauty.",

    // ** IMAGES
    // cover image with frame (looks cleaner)
    coverImg: "/assets/memento/0_Acherontia_frame.jpg",
    // ** --- cover for the gallery
    coverImgTransp: "/assets/skulls/AcherontiaAtropus_meyoko_trans.png",
    // ** --- img Top ---
    // ** not framed drawing
    imgTopUnframed: "/assets/skulls/AcherontiaAtropus_meyoko_low-r.jpg",
    //
    //
    imgDraw1: "/assets/memento/5_acherontia-by-meyoko.jpg",
    imgDraw2: "/assets/memento/16_atropos_by_meyoko_env.jpg",
    // imgDraw3: "/assets/The-furies/MegaeraByMeyoko_frame.jpg",
    // imgDraw4: "",
    // ** LG img
    imgDraw5Large: "/assets/memento/9_acherontia_by_meyoko_env.jpg",
    imgFixedBgDrawingScreen: "/assets/memento/18_memento-tryptique_licht.jpg",
    altDrawingScreen: "images of meyoko's Memento Mori Collection",

    //
    //  ---- LINK to shop
    urlToProduct:
      "https://meyoko-creative-store.company.site/products/Acherontia-p635450200",
    urlToShop: "https://meyoko-creative-store.company.site/",
    //
    //  ---- COLLECTION
    collection: "allDrawingsFolio",
    //
    // ** -- SKULL farbe --
    //       Title color
    headlineTitleLgTop: "#111111",
    svgSymbolColor: "#111111",
    //-----------
    //        BG & font 🔶
    backgroundColor: "#e8eaed", //BG blue water
    backgroundColor2: "#cfd3d8", //BG blue water
    lgTitle: "#111111",
    smTitle: "#111111",
    paragraphDescription: "#0c0c0c",
    // -----------
    // BTN'S colors
    bgButton: "#f5ede3", //powderPINK
    bgHoverButton: "transparent",
    colorFontButton: "#000000",
    colorHoverFontButton: "#f1e5cfd6",
    // icon SVG eye (attached to the btn)
    backgroundSMBtnAttached: "#fdfbf3",
    //
    //
    // **  CATEGORIES
    categoryFolioGallery: "folio-picked-drawings",
    // all press
    brand: "allDrawings",
    category: "home-screen-gallery-mementoMori",
    // category: "drawings",
    // brand: "alecto",
    allCategories: "allDrawings",
    // related posts
    relatedPost: "editorial",
    relatedPosts: [
      {
        id: 0,
        label: "editorial",
        imgRelPost: "",
      },
    ],
    // ** TAGS 🟩
    tags: [
      {
        id: 0,
        label: "drawing",
      },
      {
        id: 1,
        label: "digital",
      },
      {
        id: 2,
        label: "mementoMori",
      },
    ],
  },
  {
    _id: "4",
    extraUrlName: "alaus-oculatus",
    title: "Alaus Oculatus",
    subTitle: "Memento | Mori",
    spanTitle: "collection",
    artCreationDate: "2012",
    // ** DIMENSIONS & MATERIALS
    height: 59,
    width: 42,
    materials: "ink, acrylic, digital",
    // ** DESC (p)
    // descDraw1:
    //   "Inspired by the fusion of beauty in death, as seen in 'The Most Beautiful Dead' by Koudounaris and the blending of skeletons with masks in Maison Martin Margiela's haute couture A/W12, my goal was to evoke a harmonious interplay of life and art in my portrayal.",
    // descDraw2:
    //   "Legend tells of Megaera's birth, born from the crimson cascade of Uranus' blood when Cronus, in his rebellion, severed the ties of sky and earth. Alongside her sisters Alecto and Tisiphone, she embodies the primal forces of justice, ensuring that the scales remain balanced even in the face of defiance.",
    descDraw3:
      "This series intertwines insects, marine life, plants, and mythology, exploring symbolism with a profound emphasis on death.",
    // 🟠 SM extrait/desc of the collection
    smDescriptionAboutCollection:
      "Memento Mori, a Latin phrase meaning 'Remember you must die', invites contemplation on mortality—a reflection not of fear, but of life's fleeting beauty.",
    //
    // ** IMAGES
    coverImg: "/assets/memento/0_AlausOculatos_frame.jpg",
    // ** --- cover for the gallery
    coverImgTransp: "/assets/skulls/Alausoculatus_meyoko-low-r__trans.png",
    // ** --- img Top ---
    // ** not framed drawing
    imgTopUnframed: "/assets/skulls/Alausoculatus_meyoko-low-r.jpg",

    imgDraw1: "/assets/memento/5_alaus-by-meyoko.jpg",
    imgDraw2: "/assets/memento/16_alaus-oculatus_by_meyoko_env.jpg",
    // imgDraw3: "/assets/The-furies/MegaeraByMeyoko_frame.jpg",
    // imgDraw4: "",
    // ** LG img
    imgDraw5Large: "/assets/memento/9_alaus-oculatus_by_meyoko_env.jpg",
    imgFixedBgDrawingScreen: "/assets/memento/18_memento-tryptique_licht.jpg",
    altDrawingScreen: "images of meyoko's Memento Mori Collection",

    //
    //  ---- LINK to shop
    // urlToProduct: "",
    urlToShop: "https://meyoko-creative-store.company.site/",
    //
    //  ---- COLLECTION
    collection: "allDrawingsFolio",
    //
    // ** -- SKULL farbe --
    //       Title color
    headlineTitleLgTop: "#111111",
    svgSymbolColor: "#111111",
    //-----------
    //        BG & font 🔶
    backgroundColor: "#e8eaed", //BG blue water
    backgroundColor2: "#cfd3d8", //BG blue water
    lgTitle: "#111111",
    smTitle: "#111111",
    paragraphDescription: "#0c0c0c",
    // -----------
    // BTN'S colors
    bgButton: "#f5ede3", //powderPINK
    bgHoverButton: "transparent",
    colorFontButton: "#000000",
    colorHoverFontButton: "#f1e5cfd6",
    // icon SVG eye (attached to the btn)
    backgroundSMBtnAttached: "#fdfbf3",
    //
    //
    // **  CATEGORIES
    // all press
    brand: "allDrawings",
    category: "home-screen-gallery-mementoMori",
    // category: "drawings",
    // brand: "alecto",
    allCategories: "allDrawings",
    // related posts
    relatedPost: "editorial",
    relatedPosts: [
      {
        id: 0,
        label: "editorial",
        imgRelPost: "",
      },
    ],
    // ** TAGS 🟩
    tags: [
      {
        id: 0,
        label: "drawing",
      },
      {
        id: 1,
        label: "digital",
      },
      {
        id: 2,
        label: "mementoMori",
      },
    ],
  },
  {
    _id: "6",
    extraUrlName: "pazuzu",
    title: "Pazuzu",
    subTitle: "Memento | Mori",
    spanTitle: "collection",
    artCreationDate: "2012",
    // ** DIMENSIONS & MATERIALS
    height: 59,
    width: 42,
    materials: "ink, acrylic, digital",
    // ** DESC (p)
    descDraw1:
      "Pazuzu is renowned as the demon associated with the southwest wind, notorious for inducing famine during dry seasons and unleashing locusts during periods of rainfall.",
    descDraw2:
      "While Pazuzu is inherently an malevolent spirit, his paradoxical nature reveals a unique aspect of his character.",
    descDraw3:
      "Despite his malevolence, Pazuzu is recognized for driving away other evil spirits. In doing so, he becomes a guardian figure, actively protecting humans from plagues and misfortunes.",
    // 🟠 SM extrait/desc of the collection
    smDescriptionAboutCollection:
      "Memento Mori, a Latin phrase meaning 'Remember you must die', invites contemplation on mortality—a reflection not of fear, but of life's fleeting beauty.",
    //

    // ** IMAGES
    coverImg: "/assets/memento/0_Pazuzu__frame.jpg",
    //
    // cover img (trnasparent, great for oval divs)
    coverImgTransp: "/assets/skulls/Pazuzu_meyoko-low-r_transp.png",
    imgDraw1: "/assets/memento/5_pazuzu-by-meyoko.jpg",
    imgDraw2: "/assets/memento/16_pazuzu_by_meyoko_env.jpg",
    // imgDraw3: "/assets/The-furies/MegaeraByMeyoko_frame.jpg",
    // imgDraw4: "",
    // ** --- img Top ---
    // not framed drawing
    imgTopUnframed: "/assets/skulls/Pazuzu_meyoko-low-r.jpg",
    // ** LG img
    imgDraw5Large: "/assets/memento/9_pazuzu_by_meyoko_env.jpg",
    imgFixedBgDrawingScreen: "/assets/memento/18_memento-tryptique_licht.jpg",
    altDrawingScreen: "images of meyoko's Memento Mori Collection",

    //
    //  ---- LINK to shop
    // urlToProduct: "https://meyoko-creative.company.site/",
    urlToShop: "https://meyoko-creative-store.company.site/",
    //
    //  ---- COLLECTION
    collection: "allDrawingsFolio",
    //
    // ** -- SKULL farbe --
    //       Title color
    headlineTitleLgTop: "#111111",
    svgSymbolColor: "#111111",
    //-----------
    //        BG & font 🔶
    backgroundColor: "#e8eaed", //BG blue water
    backgroundColor2: "#cfd3d8", //BG blue water
    lgTitle: "#111111",
    smTitle: "#111111",
    paragraphDescription: "#0c0c0c",
    // -----------
    // BTN'S colors
    bgButton: "#0d0d0d",
    bgHoverButton: "transparent",
    colorFontButton: "#000000",
    colorHoverFontButton: "#f1e5cfd6",
    // btn small icon (attached to the btn)
    backgroundSMBtnAttached: "#fdfbf3",
    //
    //
    // **  CATEGORIES
    // all press
    brand: "allDrawings",
    category: "home-screen-gallery-mementoMori",
    // category: "drawings",
    // brand: "alecto",
    allCategories: "allDrawings",
    // related posts
    relatedPost: "editorial",
    relatedPosts: [
      {
        id: 0,
        label: "editorial",
        imgRelPost: "",
      },
    ],
    // ** TAGS 🟩
    tags: [
      {
        id: 0,
        label: "drawing",
      },
      {
        id: 1,
        label: "digital",
      },
      {
        id: 2,
        label: "mementoMori",
      },
    ],
  },
  {
    _id: "8",
    extraUrlName: "semiotus",
    title: "Semiotus",
    subTitle: "Memento | Mori",
    spanTitle: "collection",
    artCreationDate: "2012",
    // ** DIMENSIONS & MATERIALS
    height: 59,
    width: 42,
    materials: "ink, acrylic, digital",
    // ** DESC (p)
    descDraw1:
      "Inspired by the fusion of beauty in death, as seen in 'The Most Beautiful Dead' by Koudounaris and the blending of skeletons with masks in Maison Martin Margiela's haute couture A/W12, my goal was to evoke a harmonious interplay of life and art in my portrayal.",
    // descDraw2:
    //   "While Pazuzu is inherently an malevolent spirit, his paradoxical nature reveals a unique aspect of his character.",
    descDraw3:
      "This series intertwines insects, marine life, plants, and mythology, exploring symbolism with a profound emphasis on death.",
    // 🟠 SM extrait/desc of the collection
    smDescriptionAboutCollection:
      "Memento Mori, a Latin phrase meaning 'Remember you must die', invites contemplation on mortality—a reflection not of fear, but of life's fleeting beauty.",
    //
    //
    // ** IMAGES
    coverImg: "/assets/memento/0_Semiotus__frame.jpg",
    //
    // cover img (trnasparent, great for oval divs)

    // ** --- cover for the gallery
    coverImgTransp: "/assets/skulls/semiotus_wh_low-res_meyoko-trnsp1.png",
    // ** --- img Top ---
    // ** not framed drawing
    imgTopUnframed: "/assets/skulls/semiotus_wh_low-res_meyoko.jpg",
    //
    //
    imgDraw1: "/assets/memento/5_semiotus-bl-by-meyoko.jpg",
    imgDraw2: "/assets/memento/16_semiotus-black_by_meyoko_env.jpg",
    imgDraw3: "/assets/memento/16_semiotus-white_by_meyoko_env.jpg",
    // imgDraw4: "",
    // ** LG img
    imgDraw5Large: "/assets/memento/9_semiotus_by_meyoko_env-white.jpg",
    imgFixedBgDrawingScreen: "/assets/memento/18_memento-tryptique_licht.jpg",
    altDrawingScreen: "images of meyoko's Memento Mori Collection",

    //https://meyoko-creative-store.company.site/products/Anacridium-no-2-p635450202
    //  ---- LINK to shop
    urlToProduct:
      "https://meyoko-creative-store.company.site/products/Semiotus-p635450201",
    urlToShop: "https://meyoko-creative-store.company.site/",
    //
    //  ---- COLLECTION
    collection: "allDrawingsFolio",
    //
    // ** -- SKULL farbe --
    //       Title color
    headlineTitleLgTop: "#111111",
    svgSymbolColor: "#111111",
    //-----------
    //        BG & font 🔶
    backgroundColor: "#e8eaed", //BG blue water
    backgroundColor2: "#cfd3d8", //BG blue water
    lgTitle: "#111111",
    smTitle: "#111111",
    paragraphDescription: "#0c0c0c",
    // -----------
    // BTN'S colors
    bgButton: "#f5ede3", //powderPINK
    bgHoverButton: "transparent",
    colorFontButton: "#000000",
    colorHoverFontButton: "#f1e5cfd6",
    // icon SVG eye (attached to the btn)
    backgroundSMBtnAttached: "#fdfbf3",
    //
    //
    // **  CATEGORIES
    // all press
    brand: "allDrawings",
    category: "home-screen-gallery-mementoMori",
    // category: "drawings",
    // brand: "alecto",
    allCategories: "allDrawings",
    // related posts
    relatedPost: "editorial",
    relatedPosts: [
      {
        id: 0,
        label: "editorial",
        imgRelPost: "",
      },
    ],
    // ** TAGS 🟩
    tags: [
      {
        id: 0,
        label: "drawing",
      },
      {
        id: 1,
        label: "digital",
      },
      {
        id: 2,
        label: "mementoMori",
      },
    ],
  },
  {
    _id: "9",
    extraUrlName: "anacridium-1",
    title: "Anacridium",
    subTitle: "Memento | Mori",
    spanTitle: "collection",
    artCreationDate: "2011",
    // ** DIMENSIONS & MATERIALS
    height: 59,
    width: 42,
    materials: "ink, acrylic, digital",
    // ** DESC (p)
    descDraw1:
      "Anacridium, a genus of tree locusts or bird grasshoppers in the subfamily Cyrtacanthacridinae, stands as a focal point in the Memento Mori collection. This series intertwines insects, marine life, plants, and mythology, exploring symbolism with a profound emphasis on death",
    // descDraw2:
    //   "While Pazuzu is inherently an malevolent spirit, his paradoxical nature reveals a unique aspect of his character.",
    descDraw3:
      "This series intertwines insects, marine life, plants, and mythology, exploring symbolism with a profound emphasis on death.",
    // 🟠 SM extrait/desc of the collection
    smDescriptionAboutCollection:
      "Memento Mori, a Latin phrase meaning 'Remember you must die', invites contemplation on mortality—a reflection not of fear, but of life's fleeting beauty.",
    //
    //
    //
    // ** IMAGES
    coverImg: "/assets/memento/0_Anacridium_frame.jpg",
    //

    // ** --- cover for the gallery
    coverImgTransp: "/assets/skulls/anacridium_meyoko_transp.png",
    // ** --- img Top ---
    // ** not framed drawing
    imgTopUnframed: "/assets/skulls/anacridium_meyoko.jpg",
    //
    //
    imgDraw1: "/assets/memento/5_anacridium-by-meyoko_white.jpg",
    imgDraw2: "/assets/memento/16_anacridium-01_by_meyoko_env.jpg",
    imgDraw3: "/assets/memento/16_anacridium-01_by_meyoko_env-black.jpg",
    // imgDraw4: "",
    // ** LG img
    imgDraw5Large: "/assets/memento/9_anacridium-01_by_meyoko_env.jpg",
    imgFixedBgDrawingScreen: "/assets/memento/18_memento-tryptique_licht.jpg",
    altDrawingScreen: "images of meyoko's Memento Mori Collection",

    //
    //  ---- LINK to shop
    urlToProduct:
      "https://meyoko-creative-store.company.site/products/Anacridium-no-1-p635451637",
    //
    urlToShop: "https://meyoko-creative-store.company.site/",
    //
    //  ---- COLLECTION
    collection: "allDrawingsFolio",
    //
    // ** -- SKULL farbe --
    //       Title color
    headlineTitleLgTop: "#111111",
    svgSymbolColor: "#111111",
    //-----------
    //        BG & font 🔶
    backgroundColor: "#e8eaed", //BG blue water
    backgroundColor2: "#cfd3d8", //BG blue dark water
    lgTitle: "#111111",
    smTitle: "#111111",
    paragraphDescription: "#0c0c0c",
    // -----------
    // BTN'S colors
    bgButton: "#f5ede3", //powderPINK
    bgHoverButton: "transparent",
    colorFontButton: "#000000",
    colorHoverFontButton: "#f1e5cfd6",
    // icon SVG eye (attached to the btn)
    backgroundSMBtnAttached: "#fdfbf3",
    //
    //
    // **  CATEGORIES
    // all press
    brand: "allDrawings",
    category: "home-screen-gallery-mementoMori",
    // category: "drawings",
    // brand: "alecto",
    allCategories: "allDrawings",
    // related posts
    relatedPost: "editorial",
    relatedPosts: [
      {
        id: 0,
        label: "editorial",
        imgRelPost: "",
      },
    ],
    // ** TAGS 🟩
    tags: [
      {
        id: 0,
        label: "drawing",
      },
      {
        id: 1,
        label: "digital",
      },
      {
        id: 2,
        label: "mementoMori",
      },
    ],
  },
  //
  // ---------- NO COLLECTION
  // -----------
  {
    _id: "10",
    extraUrlName: "flora",
    title: "Flora",
    subTitle: "Fauna | Flora",
    spanTitle: "collection",
    artCreationDate: "2016",
    // ** DIMENSIONS & MATERIALS
    height: 59,
    width: 42,
    materials: "ballpoint pen",
    // ** DESC (p)
    descDraw1:
      "'Flora or Khloris' embodies the spirit of nature as the goddess of flowers and a nymph of the Islands of the Blessed. Married to Zephyros, the West Wind, she is the mother of Karpos, the god of fruit.",
    // descDraw2:
    //   "While Pazuzu is inherently an malevolent spirit, his paradoxical nature reveals a unique aspect of his character.",
    descDraw3:
      "Known as Flora in Roman mythology, Khloris symbolizes the beauty and fertility of the natural world, highlighting the interconnectedness between flora, fauna, and the elements.",
    //
    //
    // ** IMAGES
    coverImg: "/assets/drawings-no-collection/florabyMeyoko__frame.jpg",
    // ** --- cover for the gallery
    coverImgTransp: "/assets/imgDrawings/Flora-meyoko.jpg",
    // ** --- img Top ---
    // ** not framed drawing
    imgTopUnframed: "/assets/imgDrawings/Flora-meyoko.jpg",

    //
    //
    imgDraw1: "/assets/drawings-no-collection/5_flora.jpg",
    imgDraw2: "/assets/drawings-no-collection/8_flora.jpg",
    // imgDraw3: "/assets/drawings-no-collection/23__apollo__by-meyoko.jpg",
    // imgDraw4: "",
    // ** LG img
    imgDraw5Large: "/assets/drawings-no-collection/15__flora__by-meyoko.jpg",
    imgFixedBgDrawingScreen:
      "/assets/drawings-no-collection/flora_20-portrait.jpg",
    altDrawingScreen: "images of meyoko's No Collection series",

    // https://meyoko-creative-store.company.site/products/Flora-p635450951
    //  ---- LINK to shop🟨 CHNGE the links here
    urlToProduct:
      "https://meyoko-creative-store.company.site/products/Flora-p635450951",
    urlToShop: "https://meyoko-creative-store.company.site/",
    //
    //  ---- COLLECTION
    collection: "allDrawingsFolio",
    //
    //
    //
    // ** -- SKULL farbe --
    //       Title color
    headlineTitleLgTop: "#111111",
    svgSymbolColor: "#111111",
    //-----------
    //        BG & font 🔶
    backgroundColor: "#e8eaed", //BG blue water
    backgroundColor2: "#cfd3d8", //BG blue water
    lgTitle: "#111111",
    smTitle: "#111111",
    paragraphDescription: "#0c0c0c",
    // -----------
    // BTN'S colors
    bgButton: "#f5ede3", //powderPINK
    bgHoverButton: "transparent",
    colorFontButton: "#000000",
    colorHoverFontButton: "#f1e5cfd6",
    // icon SVG eye (attached to the btn)
    backgroundSMBtnAttached: "#fdfbf3",
    //
    categoryFolioGallery: "folio-picked-drawings",
    // **  CATEGORIES
    // all press
    brand: "allDrawings",
    category: "home-screen-gallery-noCollection",
    // category: "drawings",
    // brand: "alecto",
    allCategories: "allDrawings",
    // related posts
    relatedPost: "editorial",
    relatedPosts: [
      {
        id: 0,
        label: "editorial",
        imgRelPost: "",
      },
    ],
    // ** TAGS 🟩
    tags: [
      {
        id: 0,
        label: "drawing",
      },
      {
        id: 1,
        label: "collage",
      },
      // {
      //   id: 2,
      //   label: "print",
      // },
      {
        id: 3,
        label: "flora",
      },
    ],
  },
  {
    _id: "11",
    extraUrlName: "hunter-01",
    title: "'Hunter 01'",
    subTitle: "The | Hunters",
    spanTitle: "collection",
    artCreationDate: "2012",
    // ** DIMENSIONS & MATERIALS
    height: 59,
    width: 42,
    materials: "ink, acrylic",
    // ** DESC (p)
    // descDraw1:
    //   "Inspired by the fusion of beauty in death, as seen in 'The Most Beautiful Dead' by Koudounaris and the blending of skeletons with masks in Maison Martin Margiela's haute couture A/W12, my goal was to evoke a harmonious interplay of life and art in my portrayal.",
    // descDraw2:
    //   "While Pazuzu is inherently an malevolent spirit, his paradoxical nature reveals a unique aspect of his character.",
    // descDraw3:
    //   "This series intertwines insects, marine life, plants, and mythology, exploring symbolism with a profound emphasis on death.",
    // ** IMAGES
    coverImg: "/assets/Thehunters/1_hunter_01.jpg",
    // ** --- cover for the gallery
    coverImgTransp: "/assets/img-archiv/hunter_no1-by-meyoko.jpg",
    // ** --- img Top ---
    // ** not framed drawing
    imgTopUnframed: "/assets/img-archiv/hunter_no1-by-meyoko.jpg",

    //
    //
    imgDraw1: "/assets/Thehunters/23__hunter_01__by-meyoko.jpg",
    imgDraw2: "/assets/Thehunters/5_hunter1__by-meyoko.jpg",
    imgDraw3: "/assets/Thehunters/hunter_no1-by-meyoko_22.jpg",
    // imgDraw4: "",
    // ** LG img
    imgDraw5Large: "/assets/Thehunters/15__hunter01__by-meyoko.jpg",
    imgFixedBgDrawingScreen: "/assets/Thehunters/20__hunters-collection.jpg",
    altDrawingScreen: "images of meyoko's The hunters   Collection",

    //
    //  ---- LINK to shop
    // urlToProduct: "https://meyoko-creative.company.site/",
    urlToShop: "https://meyoko-creative-store.company.site/",
    //
    //  ---- COLLECTION
    collection: "allDrawingsFolio",
    //
    // ** -- farbe --
    //       Title color
    headlineTitleLgTop: "#111111",
    svgSymbolColor: "#111111",
    //-----------
    //        BG & font 🔶
    backgroundColor: "#e8eaed", //BG blue water
    backgroundColor2: "#cfd3d8", //BG blue water
    lgTitle: "#111111",
    smTitle: "#111111",
    paragraphDescription: "#0c0c0c",
    // -----------
    // BTN'S colors
    bgButton: "#f5ede3", //powderPINK
    bgHoverButton: "transparent",
    colorFontButton: "#000000",
    colorHoverFontButton: "#f1e5cfd6",
    // icon SVG eye (attached to the btn)
    backgroundSMBtnAttached: "#fdfbf3",
    //
    //
    //
    // **  CATEGORIES
    // all press
    brand: "allDrawings",
    category: "home-screen-gallery-hunters",
    // category: "drawings",
    // brand: "alecto",
    allCategories: "allDrawings",
    // related posts
    relatedPost: "editorial",
    relatedPosts: [
      {
        id: 0,
        label: "editorial",
        imgRelPost: "",
      },
    ],
    // ** TAGS 🟩
    tags: [
      {
        id: 0,
        label: "drawing",
      },
      {
        id: 1,
        label: "collage",
      },
      {
        id: 2,
        label: "hunter1",
      },
    ],
  },
  {
    _id: "12",
    extraUrlName: "notus",
    title: "Notus",
    subTitle: "The | Anemoi",
    spanTitle: "collection",
    artCreationDate: "2016",
    // ** DIMENSIONS & MATERIALS
    height: 59,
    width: 42,
    materials: "gold, watercolor, ink",
    // ** DESC (p)
    descDraw1:
      "Notus, the Greek god of the South Wind, symbolized the scorching breeze that intensified with the rise of Sirius after midsummer. Revered for its desiccating heat, Notus bore the responsibility of ushering in the storms during late summer and autumn. ",
    descDraw2:
      "Regarded as a feared force, he was often associated with the ominous role of a crop destroyer, casting a shadow over the agricultural landscape.",
    descDraw3:
      "In the ancient tales, Notus emerges as a powerful and enigmatic deity, weaving his influence through the changing seasons and leaving an indelible mark on the natural world.",
    // ** IMAGES
    coverImg: "/assets/drawings-no-collection/notosByMeyoko__frame.jpg",
    // ** --- cover for the gallery
    coverImgTransp: "/assets/imgDrawings/Notos_meyoko.jpg",
    // ** --- img Top ---
    // ** not framed drawing
    imgTopUnframed: "/assets/imgDrawings/Notos_meyoko.jpg",

    imgDraw1: "/assets/drawings-no-collection/5__notus-__by-meyoko.jpg",
    imgDraw2: "/assets/drawings-no-collection/6__notus-__by-meyoko.jpg",
    // imgDraw3: "/assets/drawings-no-collection/23__apollo__by-meyoko.jpg",
    // imgDraw4: "",
    // ** LG img
    imgDraw5Large: "/assets/drawings-no-collection/15__notus-__by-meyoko.jpg",
    imgFixedBgDrawingScreen:
      "/assets/drawings-no-collection/notus_15-portrait.jpg",
    altDrawingScreen: "images of meyoko's No Collection series",

    urlToProduct:
      "https://meyoko-creative-store.company.site/products/Notus-p635451636",
    urlToShop: "https://meyoko-creative-store.company.site",
    //
    //  ---- COLLECTION
    collection: "allDrawingsFolio",
    //
    //
    // ** -- farbe --
    //       Title color
    headlineTitleLgTop: "#111111",
    svgSymbolColor: "#111111",
    //-----------
    //        BG & font 🔶
    backgroundColor: "#e8eaed", //BG blue water
    backgroundColor2: "#cfd3d8", //BG blue water
    lgTitle: "#111111",
    smTitle: "#111111",
    paragraphDescription: "#0c0c0c",
    // -----------
    // BTN'S colors
    bgButton: "#f5ede3", //powderPINK
    bgHoverButton: "transparent",
    colorFontButton: "#000000",
    colorHoverFontButton: "#f1e5cfd6",
    // icon SVG eye (attached to the btn)
    backgroundSMBtnAttached: "#fdfbf3",
    //
    //
    categoryFolioGallery: "folio-picked-drawings",
    // **  CATEGORIES
    // all press
    brand: "allDrawings",
    category: "home-screen-gallery-noCollection",
    // category: "drawings",
    // brand: "alecto",
    allCategories: "allDrawings",
    // related posts
    relatedPost: "editorial",
    relatedPosts: [
      {
        id: 0,
        label: "editorial",
        imgRelPost: "",
      },
    ],
    // ** TAGS 🟩
    tags: [
      {
        id: 0,
        label: "drawing",
      },
      {
        id: 1,
        label: "collage",
      },
      {
        id: 2,
        label: "notus",
      },
    ],
  },
  {
    _id: "13",
    extraUrlName: "Asmodeo",
    title: "Asmodeo",
    subTitle: "Fauna | Flora",
    spanTitle: "collection",
    artCreationDate: "2012",
    // ** DIMENSIONS & MATERIALS
    height: 59,
    width: 42,
    materials: "Acrylic, watercolor, ink",
    // ** DESC (p)
    descDraw1:
      "Asmodeo, Kind of Demons and Avatar of Lust, fell for the Maiden Sarah. When her father Raguel betrothed her to another man, Asmodeo murdered him before he could consummate the union with Sarah.",
    descDraw2:
      " This did not deterr her father, as he went on to betroth her 6 more times, each with the same results for the grooms.",
    // descDraw3:
    //   "This series intertwines insects, marine life, plants, and mythology, exploring symbolism with a profound emphasis on death.",
    // ** IMAGES
    coverImg:
      "/assets/drawings-no-collection/tete-de-femme_byMeyoko__frame.jpg",

    // ** --- cover for the gallery
    coverImgTransp: "",
    // ** --- img Top ---
    // ** not framed drawing
    imgTopUnframed:
      "/assets/imgDrawings/tete-de-femme_melissamurillo-meyoko.jpg",

    //
    //
    imgDraw1: "/assets/drawings-no-collection/23__tete_de_femme__by-meyoko.jpg",
    imgDraw2: "/assets/drawings-no-collection/5__tete_de_femme__by-meyoko.jpg",
    // imgDraw3: "/assets/drawings-no-collection/23__apollo__by-meyoko.jpg",
    // imgDraw4: "",
    // ** LG img
    imgDraw5Large:
      "/assets/drawings-no-collection/15__tete_de_femme__by-meyoko.jpg",
    imgFixedBgDrawingScreen:
      "/assets/drawings-no-collection/tete-de-femme_20-portrait.jpg",
    altDrawingScreen: "images of meyoko's No Collection series",

    //
    //  ---- LINK to shop🟨 CHNGE the links here
    // urlToProduct: "https://meyoko-creative.company.site/",
    urlToShop: "https://meyoko-creative-store.company.site/",
    //
    //  ---- COLLECTION
    collection: "allDrawingsFolio",
    //
    //
    // ** --
    //       Title color
    headlineTitleLgTop: "#111111",
    svgSymbolColor: "#111111",
    //-----------
    //        BG & font 🔶
    backgroundColor: "#e8eaed", //BG blue water
    backgroundColor2: "#cfd3d8", //BG blue water
    lgTitle: "#111111",
    smTitle: "#111111",
    paragraphDescription: "#0c0c0c",
    // -----------
    // BTN'S colors
    bgButton: "#f5ede3", //powderPINK
    bgHoverButton: "transparent",
    colorFontButton: "#000000",
    colorHoverFontButton: "#f1e5cfd6",
    // icon SVG eye (attached to the btn)
    backgroundSMBtnAttached: "#fdfbf3",
    //
    //
    //
    // **  CATEGORIES
    // all press
    brand: "allDrawings",
    category: "home-screen-gallery-noCollection",
    // category: "drawings",
    // brand: "alecto",
    allCategories: "allDrawings",
    // related posts
    relatedPost: "editorial",
    relatedPosts: [
      {
        id: 0,
        label: "editorial",
        imgRelPost: "",
      },
    ],
    // ** TAGS 🟩
    tags: [
      {
        id: 0,
        label: "drawing",
      },
      {
        id: 1,
        label: "collage",
      },
      {
        id: 2,
        label: "tete-de-femme",
      },
    ],
  },
  {
    _id: "14",
    extraUrlName: "medea",
    title: "Medea",
    subTitle: "Fauna | Flora",
    spanTitle: "collection",
    artCreationDate: "2012",
    // ** DIMENSIONS & MATERIALS
    height: 59,
    width: 42,
    materials: "watercolor, ink",
    // ** DESC (p)
    descDraw1:
      " Ondine was a water nymth of great Beauty, who, like most of her kind, was cursed to an existence without a soul.",
    descDraw2:
      " As she approached shore, she set eyes upon a sailor, and it was love at first sight. Her newfound emotions allowed her to morph her resplendent tail into human legs, and she promptly bonded him.",
    descDraw3:
      " But it was not meant to last. Her sailor broke his promise of everlasting loyalty to her. Consumed with grief, Ondine devoured his soul and made it hers, before returning to the dark depths of the seas, where she belonged",
    // ** IMAGES
    coverImg: "/assets/drawings-no-collection/MedeabyMeyoko__frame-01.jpg",
    // ** --- cover for the gallery
    coverImgTransp: "/assets/img-archiv/Medea_melissamurilllo-meyoko.jpg",
    // ** --- img Top ---
    // ** not framed drawing
    imgTopUnframed: "/assets/img-archiv/Medea_melissamurilllo-meyoko.jpg",
    //
    //
    imgDraw1: "/assets/drawings-no-collection/23__medea__by-meyoko.jpg",
    imgDraw2: "/assets/drawings-no-collection/5__medea__by-meyoko.jpg",
    // imgDraw3: "/assets/drawings-no-collection/23__apollo__by-meyoko.jpg",
    // imgDraw4: "",
    // ** LG img
    imgDraw5Large: "/assets/drawings-no-collection/15__medea__by-meyoko.jpg",
    imgFixedBgDrawingScreen:
      "/assets/drawings-no-collection/medea_20-portrait.jpg",
    altDrawingScreen: "images of meyoko's No Collection series",

    //
    //  ---- LINK to shop🟨 CHNGE the links here
    // urlToProduct: "https://meyoko-creative.company.site/",
    urlToShop: "https://meyoko-creative-store.company.site/",
    //
    //  ---- COLLECTION
    collection: "allDrawingsFolio",
    //
    //
    // ** --   --
    //       Title color
    headlineTitleLgTop: "#111111",
    svgSymbolColor: "#111111",
    //-----------
    //        BG & font 🔶
    backgroundColor: "#e8eaed", //BG blue water
    backgroundColor2: "#cfd3d8", //BG blue dark water
    lgTitle: "#111111",
    smTitle: "#111111",
    paragraphDescription: "#0c0c0c",
    // -----------
    // BTN'S colors
    bgButton: "#f5ede3", //powderPINK
    bgHoverButton: "transparent",
    colorFontButton: "#000000",
    colorHoverFontButton: "#f1e5cfd6",
    // icon SVG eye (attached to the btn)
    backgroundSMBtnAttached: "#fdfbf3",
    //
    // **  CATEGORIES
    // all press
    brand: "allDrawings",
    category: "home-screen-gallery-noCollection",
    // category: "drawings",
    // brand: "alecto",
    allCategories: "allDrawings",
    // related posts
    relatedPost: "editorial",
    relatedPosts: [
      {
        id: 0,
        label: "editorial",
        imgRelPost: "",
      },
    ],
    // ** TAGS 🟩
    tags: [
      {
        id: 0,
        label: "drawing",
      },
      {
        id: 1,
        label: "collage",
      },
      {
        id: 2,
        label: "medea",
      },
    ],
  },
  {
    _id: "15",
    extraUrlName: "apollo-and-daphne",
    title: "Apollo",
    subTitle: "Fauna | Flora",
    spanTitle: "collection",
    artCreationDate: "2012",
    // ** DIMENSIONS & MATERIALS
    height: 59,
    width: 42,
    materials: "ballpoint pen",
    // ** DESC (p)
    descDraw1:
      "Apollo, God of Medicine, Music and Charioteer of the Sun, was renown for the unfortunate fates of his lovers.",
    descDraw2:
      "One such tale begins with a disagreement he had with Eros, God of Love. To punish Apollo for his arrogance, Eros crafted two special arrows to strike our lovers, one to entice its receiver to pursue, and the other to reject.",
    descDraw3:
      "Daphne, nymph and daughter of the river deity Landon, was struck with the latter, and thus ran ferverently from Apollon Phoebus when he started pursuing her.In her desperation, Daphne prayed to her father for salvation, for she could never love Apollo, and Ladon aided her by transforming her into the sacred Laurel tree. ",
    // ** IMAGES
    coverImg: "/assets/drawings-no-collection/ApollobyMeyoko__frame-01.jpg",
    // ** --- cover for the gallery
    coverImgTransp: "/assets/imgDrawings/apollo-grand.jpg",
    // ** --- img Top ---
    // ** not framed drawing
    imgTopUnframed: "/assets/imgDrawings/apollo-grand.jpg",

    //
    imgDraw1: "/assets/drawings-no-collection/22__apollo.jpg",
    imgDraw2: "/assets/drawings-no-collection/5_apollo_and_daphne.jpg",
    imgDraw3: "/assets/drawings-no-collection/23__apollo__by-meyoko.jpg",
    // imgDraw4: "",
    // ** LG img
    imgDraw5Large: "/assets/drawings-no-collection/15__apollo__by-meyoko.jpg",
    imgFixedBgDrawingScreen:
      "/assets/drawings-no-collection/apollo-and-daphne_20-portrait.jpg",
    altDrawingScreen: "images of meyoko's No Collection series",

    //
    //  ---- LINK to shop🟨 CHNGE the links here
    // urlToProduct: "https://meyoko-creative.company.site/",
    urlToShop: "https://meyoko-creative-store.company.site/",
    //
    //  ---- COLLECTION
    collection: "allDrawingsFolio",
    //
    //
    // ** --  farbe --
    //       Title color
    headlineTitleLgTop: "#111111",
    svgSymbolColor: "#111111",
    //-----------
    //        BG & font 🔶
    backgroundColor: "#e8eaed", //BG blue water
    backgroundColor2: "#cfd3d8", //BG blue dark water
    lgTitle: "#111111",
    smTitle: "#111111",
    paragraphDescription: "#0c0c0c",
    // -----------
    // BTN'S colors
    bgButton: "#f5ede3", //powderPINK
    bgHoverButton: "transparent",
    colorFontButton: "#000000",
    colorHoverFontButton: "#f1e5cfd6",
    // icon SVG eye (attached to the btn)
    backgroundSMBtnAttached: "#fdfbf3",
    //
    //
    //
    // **  CATEGORIES
    // all press
    brand: "allDrawings",
    category: "home-screen-gallery-noCollection",
    // category: "drawings",
    // brand: "alecto",
    allCategories: "allDrawings",
    // related posts
    relatedPost: "editorial",
    relatedPosts: [
      {
        id: 0,
        label: "editorial",
        imgRelPost: "",
      },
    ],
    // ** TAGS 🟩
    tags: [
      {
        id: 0,
        label: "drawing",
      },
      {
        id: 1,
        label: "collage",
      },
      {
        id: 2,
        label: "apollo",
      },
    ],
  },
  {
    _id: "16",
    extraUrlName: "nephila",
    title: "Nephila",
    subTitle: "The | Nephila",
    spanTitle: "collection",
    artCreationDate: "2015",
    // ** DIMENSIONS & MATERIALS
    height: 59,
    width: 42,
    materials: "ink, acrylic",
    // ** DESC (p)
    descDraw1:
      "Nephila draws inspiration from the mythology of Kiribati, particularly the story of Nareau, the creator deity. Meyoko's incorporated elements from Nareau's myth, such as his transformation into a spider to weave the fabric of existence.",
    // descDraw2:
    //   "According to Persian myth, the Simurgh resided in the Tree of Knowledge, an ancient symbol rich with symbolism.",
    descDraw3:
      " Additionally, Nephila features imagery inspired by the spider 'Nephila clavata', known as the fortune teller spider, symbolized by the central eye representing its all-seeing nature.",
    // ** IMAGES
    coverImg: "/assets/drawings-no-collection/nephila_byMeyoko__frame-1.jpg",
    // ** --- cover for the gallery
    coverImgTransp: "/assets/imgDrawings/nephila-wh-low-res_meyoko.jpg",
    // ** --- img Top ---
    // ** not framed drawing
    imgTopUnframed: "/assets/imgDrawings/nephila-wh-low-res_meyoko.jpg",
    //
    //
    imgDraw1: "/assets/drawings-no-collection/9__nephila.jpg",
    imgDraw2: "/assets/drawings-no-collection/13_nephila.jpg",
    imgDraw3: "/assets/drawings-no-collection/23_nephila__by-meyoko.jpg ",
    // imgDraw4:  "/assets/drawings-no-collection/ ",
    // ** LG img
    imgDraw5Large: "/assets/drawings-no-collection/15__nephila__by-meyoko.jpg",
    imgFixedBgDrawingScreen: "/assets/drawings-no-collection/20_nephila.jpg",
    altDrawingScreen: "images of meyoko's The Simurghs Collection",

    //
    //  ---- LINK to shop
    // urlToProduct: "https://meyoko-creative.company.site/products/nephila",
    urlToShop: "https://meyoko-creative-store.company.site/",
    //
    //  ---- COLLECTION
    collection: "allDrawingsFolio",
    //
    //
    // ** -- The FURIES farbe --
    //       Title color
    headlineTitleLgTop: "#111111",
    svgSymbolColor: "#111111",
    //-----------
    //        BG & font 🔶
    backgroundColor: "#fff0c0", // yellow
    // #f9d3c72f  BG powder pink, this color has a transparency that mixed with the yellow bg of the MAIN, creates a nice shade of pink
    backgroundColor2: "#fff0c0", // yellow
    lgTitle: "#111111",
    smTitle: "#111111",
    paragraphDescription: "#0c0c0c",
    // -----------
    // -----------
    // BTN'S colors
    bgButton: "#0d0d0d",
    bgHoverButton: "transparent",
    colorFontButton: "#000000",
    colorHoverFontButton: "#f1e5cfd6",
    // btn small icon (attached to the btn)
    backgroundSMBtnAttached: "#fdfbf3",
    //
    //
    //
    // **  CATEGORIES
    // all press
    brand: "allDrawings",
    category: "home-screen-gallery-noCollection",
    // category: "drawings",
    // brand: "alecto",
    allCategories: "allDrawings",
    // related posts
    relatedPost: "editorial",
    // relatedPosts: [
    //   {
    //     id: 0,
    //     label: "editorial",
    //     imgRelPost: "",
    //   },
    // ],
    // ** TAGS 🟩
    tags: [
      {
        id: 0,
        label: "drawing",
      },
      {
        id: 1,
        label: "collage",
      },
      {
        id: 2,
        label: "nephila",
      },
    ],
  },
  {
    _id: "17",
    extraUrlName: "simurgh-1",
    title: "Simurgh 01",
    subTitle: "The | Simurgh",
    spanTitle: "collection",
    artCreationDate: "2015",
    // ** DIMENSIONS & MATERIALS
    height: 59,
    width: 42,
    materials: "ballpoint pen, acrylic on paper",
    // ** DESC (p)
    descDraw1:
      " Meyoko's 'Simurgh' draws inspiration from the mystical tales of a legendary creature known as the Simurgh. Unlike some of its counterparts in mythology, renowned for their trickery, the Simurgh is revered for its benevolence and healing powers.",
    descDraw2:
      "According to Persian myth, the Simurgh resided in the Tree of Knowledge, an ancient symbol rich with symbolism.",
    descDraw3:
      "As the Simurgh took flight, the force of its ascent shook the tree's branches so vigorously that seeds from every plant ever known were scattered throughout the world, bestowing upon humanity a bounty of valuable flora.",
    //
    //
    // ** IMAGES
    coverImg: "/assets/simurgh-collection/simurgh_01-frame.jpg",
    // ** --- cover for the gallery
    coverImgTransp: "/assets/imgDrawings/simurgh-grand.jpg",
    // ** --- img Top ---
    // ** not framed drawing
    imgTopUnframed: "/assets/imgDrawings/simurgh-grand.jpg",
    //
    //
    imgDraw1: "/assets/simurgh-collection/9__simurgh-01.jpg",
    imgDraw2: "/assets/simurgh-collection/8__simurgh-01.jpg",
    imgDraw3:
      "/assets/simurgh-collection/14__simurgh_collect_pair__by-meyoko.jpg",
    // imgDraw4: "",
    // -----
    //   LG img
    imgDraw5Large: "/assets/simurgh-collection/1__simurgh-01.jpg",

    // FIXED footer img
    imgFixedBgDrawingScreen:
      "/assets/simurgh-collection/20_portrait-simurgh_01.jpg",
    altDrawingScreen: "images of meyoko's No Collection series",

    //
    //  ---- LINK to shop CHNGE the links here
    // urlToProduct: "https://meyoko-creative.company.site/",
    urlToShop: "https://meyoko-creative-store.company.site/",
    //
    //  ---- COLLECTION
    collection: "allDrawingsFolio",
    //
    //
    // ** -- The FURIES farbe --
    //       Title color
    headlineTitleLgTop: "#111111",
    svgSymbolColor: "#111111",
    //-----------
    //        BG & font 🔶
    backgroundColor: "#fff0c0", // yellow
    // #f9d3c72f  BG powder pink, this color has a transparency that mixed with the yellow bg of the MAIN, creates a nice shade of pink
    backgroundColor2: "#fff0c0", // yellow
    lgTitle: "#111111",
    smTitle: "#111111",
    paragraphDescription: "#0c0c0c",
    // -----------
    // -----------
    // BTN'S colors
    bgButton: "#0d0d0d",
    bgHoverButton: "transparent",
    colorFontButton: "#000000",
    colorHoverFontButton: "#f1e5cfd6",
    // btn small icon (attached to the btn)
    backgroundSMBtnAttached: "#fdfbf3",
    //
    //
    //
    // **  CATEGORIES
    categoryFolioGallery: "folio-picked-drawings",
    // all press
    brand: "allDrawings",
    category: "home-screen-gallery-simurgh-collection",
    // category: "drawings",
    // brand: "alecto",
    allCategories: "allDrawings",
    // related posts
    relatedPost: "editorial",
    relatedPosts: [
      {
        id: 0,
        label: "editorial",
        imgRelPost: "",
      },
    ],
    // ** TAGS 🟩
    tags: [
      {
        id: 0,
        label: "drawing",
      },
      {
        id: 1,
        label: "collage",
      },
      {
        id: 2,
        label: "simurgh-1",
      },
    ],
  },
  {
    _id: "18",
    extraUrlName: "simurgh-2",
    title: "Simurgh 02",
    subTitle: "The | Simurgh",
    spanTitle: "collection",
    artCreationDate: "2017",
    // ** DIMENSIONS & MATERIALS
    height: 59,
    width: 42,
    materials: "ballpoint pen, acrylic on paper",
    // ** DESC (p)
    descDraw1:
      " Meyoko's 'Simurgh' draws inspiration from the mystical tales of a legendary creature known as the Simurgh. Unlike some of its counterparts in mythology, renowned for their trickery, the Simurgh is revered for its benevolence and healing powers.",
    descDraw2:
      "According to Persian myth, the Simurgh resided in the Tree of Knowledge, an ancient symbol rich with symbolism.",
    descDraw3:
      "As the Simurgh took flight, the force of its ascent shook the tree's branches so vigorously that seeds from every plant ever known were scattered throughout the world, bestowing upon humanity a bounty of valuable flora.",
    //
    //
    // ** IMAGES
    coverImg: "/assets/simurgh-collection/1__simurgh-02.jpg",
    // ** --- cover for the gallery
    coverImgTransp: "/assets/imgDrawings/Simurgh_no2_melissamurillo-meyoko.jpg",
    // ** --- img Top ---
    // ** not framed drawing
    imgTopUnframed: "/assets/imgDrawings/Simurgh_no2_melissamurillo-meyoko.jpg",

    //
    //
    imgDraw1: "/assets/simurgh-collection/23__simurgh_02__by-meyoko.jpg",
    imgDraw2: "/assets/simurgh-collection/5__simurgh_02__by-meyoko.jpg",
    imgDraw3:
      "/assets/simurgh-collection/14__simurgh_collect_pair__by-meyoko.jpg",
    // imgDraw4: "/assets/simurgh-collection/23__simurgh_02__by-meyoko.jpg",
    // -----
    // 🟨 LG img
    imgDraw5Large: "/assets/simurgh-collection/15__simurgh_02__by-meyoko.jpg",

    // FIXED footer img
    imgFixedBgDrawingScreen:
      "/assets/simurgh-collection/20_portrait-simurgh_01.jpg",
    altDrawingScreen: "images of meyoko's No Collection series",

    //
    //  ---- LINK to shop🟨 CHNGE the links here
    // urlToProduct: "https://meyoko-creative.company.site/",
    urlToShop: "https://meyoko-creative-store.company.site/",
    //
    //  ---- COLLECTION
    collection: "allDrawingsFolio",
    //
    //
    //        BG & font 🔶
    backgroundColor: "#fff0c0", // yellow
    // #f9d3c72f  BG powder pink, this color has a transparency that mixed with the yellow bg of the MAIN, creates a nice shade of pink
    backgroundColor2: "#fff0c0", // yellow
    lgTitle: "#111111",
    smTitle: "#111111",
    paragraphDescription: "#0c0c0c",
    // -----------
    // -----------
    // BTN'S colors
    bgButton: "#0d0d0d",
    bgHoverButton: "transparent",
    colorFontButton: "#000000",
    colorHoverFontButton: "#f1e5cfd6",
    // btn small icon (attached to the btn)
    backgroundSMBtnAttached: "#fdfbf3",
    //
    //
    // **  CATEGORIES
    // all press
    brand: "allDrawings",
    category: "home-screen-gallery-simurgh-collection",
    // category: "drawings",
    // brand: "alecto",
    allCategories: "allDrawings",
    // related posts
    relatedPost: "editorial",
    relatedPosts: [
      {
        id: 0,
        label: "editorial",
        imgRelPost: "",
      },
    ],
    // ** TAGS 🟩
    tags: [
      {
        id: 0,
        label: "drawing",
      },
      {
        id: 1,
        label: "collage",
      },
      {
        id: 2,
        label: "simurgh-2",
      },
    ],
  },
  {
    _id: "19",
    extraUrlName: "lilith",
    title: "Lilith",
    subTitle: "Fauna | Flora",
    spanTitle: "collection",
    artCreationDate: "2018",
    // ** DIMENSIONS & MATERIALS
    height: 59,
    width: 84,
    materials: "ink, acrylic",
    // ** DESC (p)
    descDraw1:
      "As Adam's initial companion, Lilith refused to be confined by conventional roles, challenging the patriarchal norms of her time. Her tale unfolds as a narrative of empowerment, as she embraces her autonomy and demands equality in the divine realm.",
    descDraw2:
      "Lilith's expulsion from Paradise is not a tale of defeat but a radiant assertion of her individuality.",
    descDraw3:
      "Draped in defiance, she symbolizes the unyielding spirit of feminism, inspiring generations to reject limitations and embrace their inherent strength.",
    // ** IMAGES
    coverImg: "/assets/drawings-no-collection/lilith-lg_frame-1.jpg",
    // ** --- img Top ---
    // not framed drawing
    imgTopUnframed: "/assets/imgDrawings/lilith2_grand-by_meyoko.jpg",
    //
    //
    imgDraw1: "/assets/drawings-no-collection/1_lilith-lg.jpg",
    imgDraw2: "/assets/drawings-no-collection/3_lilith-lg.jpg",
    imgDraw3: "/assets/drawings-no-collection/9_lilith-lg.jpg",
    // imgDraw4:  "/assets/drawings-no-collection/ ",
    // ** LG img
    imgDraw5Large: "/assets/drawings-no-collection/15__lilith-lg.jpg",
    imgFixedBgDrawingScreen:
      "/assets/drawings-no-collection/lilith-lg_frame-5.jpg",
    altDrawingScreen: "images of meyoko's The Lilith's Collection",

    //
    //  ---- LINK to shop
    // urlToProduct: "https://meyoko-creative.company.site/",
    urlToShop: "https://meyoko-creative-store.company.site/",
    //
    //  ---- COLLECTION
    collection: "allDrawingsFolio",
    //
    //
    //       Title color
    headlineTitleLgTop: "#111111",
    svgSymbolColor: "#111111",
    //-----------
    //        BG & font 🔶
    backgroundColor: "#fff0c0", // yellow
    // #f9d3c72f  BG powder pink, this color has a transparency that mixed with the yellow bg of the MAIN, creates a nice shade of pink
    backgroundColor2: "#fff0c0", // yellow
    lgTitle: "#111111",
    smTitle: "#111111",
    paragraphDescription: "#0c0c0c",
    // -----------
    // -----------
    // BTN'S colors
    bgButton: "#0d0d0d",
    bgHoverButton: "transparent",
    colorFontButton: "#000000",
    colorHoverFontButton: "#f1e5cfd6",
    // btn small icon (attached to the btn)
    backgroundSMBtnAttached: "#fdfbf3",
    //
    categoryFolioGallery: "folio-picked-drawings",
    // **  CATEGORIES
    // all press
    brand: "allDrawings",
    category: "home-screen-gallery-noCollection",
    // category: "drawings",
    // brand: "alecto",
    allCategories: "allDrawings",
    // related posts
    relatedPost: "editorial",
    // relatedPosts: [
    //   {
    //     id: 0,
    //     label: "editorial",
    //     imgRelPost: "",
    //   },
    // ],
    // ** TAGS 🟩
    tags: [
      {
        id: 0,
        label: "drawing",
      },
      {
        id: 1,
        label: "graphite",
      },
      {
        id: 2,
        label: "lilith-1",
      },
    ],
  },
  {
    _id: "20",
    extraUrlName: "volta",
    title: "Volta",
    subTitle: "Debut",
    spanTitle: "collection",
    artCreationDate: "2010",
    // ** DIMENSIONS & MATERIALS
    height: 59,
    width: 42,
    materials: "Ballpoint pen on paper",
    // ** DESC (p)
    // descDraw1:
    //   "",
    // descDraw2:
    //   " ",
    // descDraw3:
    //   " ",
    // ** IMAGES
    coverImg: "/assets/img-archiv/volta_by_meyoko_frame.jpg",
    // ** --- img Top ---
    // not framed drawing
    imgTopUnframed: "/assets/img-archiv/volta_by_meyoko-melissamurillo.jpg",
    //
    //
    imgDraw1: "/assets/img-archiv/volta_by_meyoko-melissamurillo.jpg",
    //
    imgDraw2: "/assets/img-archiv/volta_by_meyoko-melissamurillo_10.jpg",
    imgDraw3: "/assets/img-archiv/volta_by_meyoko-melissamurillo_15.jpg",
    // imgDraw4:  "/assets/drawings-no-collection/ ",
    // ** LG img
    imgDraw5Large: "/assets/img-archiv/volta_by_meyoko-melissamurillo.jpg",
    imgFixedBgDrawingScreen:
      "/assets/img-archiv/volta_by_meyoko-melissamurillo.jpg",
    altDrawingScreen: "images of meyoko's volta from  The Debut's Collection",

    //
    //  ---- LINK to shop
    // urlToProduct: "https://meyoko-creative.company.site/",
    urlToShop: "https://meyoko-creative-store.company.site/",
    //
    //  ---- COLLECTION
    // Not to be seen on the main FOLIO page (just on the tags )
    collection: "allDrawingsFolio",
    //
    //
    //       Title color
    headlineTitleLgTop: "#111111",
    svgSymbolColor: "#111111",
    //-----------
    //        BG & font 🔶
    backgroundColor: "#fff0c0", // yellow
    // #f9d3c72f  BG powder pink, this color has a transparency that mixed with the yellow bg of the MAIN, creates a nice shade of pink
    backgroundColor2: "#fff0c0", // yellow
    lgTitle: "#111111",
    smTitle: "#111111",
    paragraphDescription: "#0c0c0c",
    // -----------
    // -----------
    // BTN'S colors
    bgButton: "#0d0d0d",
    bgHoverButton: "transparent",
    colorFontButton: "#000000",
    colorHoverFontButton: "#f1e5cfd6",
    // btn small icon (attached to the btn)
    backgroundSMBtnAttached: "#fdfbf3",
    //
    //
    // **
    // categoryFolioGallery: "folio-picked-drawings",
    // **  CATEGORIES
    // all press
    brand: "allDrawings",
    // category: "home-screen-gallery-noCollection",
    // category: "drawings",
    // brand: "alecto",
    allCategories: "allDrawings",
    // related posts
    relatedPost: "editorial",
    // relatedPosts: [
    //   {
    //     id: 0,
    //     label: "editorial",
    //     imgRelPost: "",
    //   },
    // ],
    // ** TAGS 🟩
    tags: [
      {
        id: 0,
        label: "drawing",
      },
      // {
      //   id: 1,
      //   label: "graphite",
      // },
      {
        id: 2,
        label: "volta",
      },
    ],
  },

  {
    _id: "21",
    extraUrlName: "aellopus",
    title: "Aellopus",
    subTitle: "Debut",
    spanTitle: "collection",
    artCreationDate: "2010",
    // ** DIMENSIONS & MATERIALS
    height: 59,
    width: 84,
    materials: "Ballpoint pen, gold powder, acrylic and collage on paper",
    // ** DESC (p)
    // descDraw1:
    //   "",
    // descDraw2:
    //   " ",
    // descDraw3:
    //   " ",
    // ** IMAGES
    coverImg: "/assets/img-archiv/aellopus__by_meyoko-frame.jpg",
    // ** --- img Top ---
    // not framed drawing
    imgTopUnframed: "/assets/img-archiv/aellopus__by_meyoko-low.jpg",
    //
    //
    imgDraw1: "/assets/img-archiv/aellopus__by_meyoko-low.jpg",
    imgDraw2: "/assets/img-archiv/aellopus__by_meyoko_frame_15-low.jpg",
    // imgDraw3: "/assets/drawings-no-collection/9_lilith-lg.jpg",
    // imgDraw4:  "/assets/drawings-no-collection/ ",
    // ** LG img
    imgDraw5Large: "/assets/img-archiv/volta_by_meyoko-melissamurillo.jpg",
    imgFixedBgDrawingScreen:
      "/assets/img-archiv/volta_by_meyoko-melissamurillo.jpg",
    altDrawingScreen: "images of meyoko's volta from  The Debut's Collection",

    //
    //  ---- LINK to shop
    // urlToProduct: "https://meyoko-creative.company.site/",
    urlToShop: "https://meyoko-creative-store.company.site/",
    //
    //  ---- COLLECTION
    // Not to be seen on the main FOLIO page (just on the tags )
    collection: "allDrawingsFolio",
    //
    //
    //       Title color
    headlineTitleLgTop: "#111111",
    svgSymbolColor: "#111111",
    //-----------
    //        BG & font 🔶
    backgroundColor: "#fff0c0", // yellow
    // #f9d3c72f  BG powder pink, this color has a transparency that mixed with the yellow bg of the MAIN, creates a nice shade of pink
    backgroundColor2: "#fff0c0", // yellow
    lgTitle: "#111111",
    smTitle: "#111111",
    paragraphDescription: "#0c0c0c",
    // -----------
    // -----------
    // BTN'S colors
    bgButton: "#0d0d0d",
    bgHoverButton: "transparent",
    colorFontButton: "#000000",
    colorHoverFontButton: "#f1e5cfd6",
    // btn small icon (attached to the btn)
    backgroundSMBtnAttached: "#fdfbf3",
    //
    //
    // **
    // categoryFolioGallery: "folio-picked-drawings",
    // **  CATEGORIES
    // all press
    brand: "allDrawings",
    // category: "home-screen-gallery-noCollection",
    // category: "drawings",
    // brand: "alecto",
    allCategories: "allDrawings",
    // related posts
    relatedPost: "editorial",
    // relatedPosts: [
    //   {
    //     id: 0,
    //     label: "editorial",
    //     imgRelPost: "",
    //   },
    // ],
    // ** TAGS 🟩
    tags: [
      {
        id: 0,
        label: "drawing",
      },
      // {
      //   id: 1,
      //   label: "graphite",
      // },
      {
        id: 2,
        label: "Aellopus",
      },
    ],
  },
  {
    _id: "22",
    extraUrlName: "oblivion",
    title: "Oblivion",
    subTitle: "Fauna | Flora",
    spanTitle: "collection",
    artCreationDate: "2015",
    // ** DIMENSIONS & MATERIALS
    height: 30,
    width: 30,
    materials: "Ballpoint pen, gold powder, acrylic and collage on paper",
    // ** DESC (p)
    // descDraw1:
    //   "",
    // descDraw2:
    //   " ",
    // descDraw3:
    //   " ",
    // ** IMAGES
    // cover img on the TAG page
    coverImg: "/assets/img-archiv/oblivion_by_meyoko-frame.jpg",
    // ** --- img Top ---
    // not framed drawing
    imgTopUnframed: "/assets/img-archiv/oblivion_coverimg_by_meyoko.jpg",
    //
    //
    imgDraw1: "/assets/img-archiv/oblivion_by_meyoko-10.jpg",
    imgDraw2: "/assets/img-archiv/oblivion_by_meyoko.jpg",
    // imgDraw3: "/assets/img-archiv/oblivion_by_meyoko.jpg",
    // imgDraw4:  "/assets/drawings-no-collection/ ",
    // ** LG img
    imgDraw5Large: "/assets/img-archiv/volta_by_meyoko-melissamurillo.jpg",
    imgFixedBgDrawingScreen:
      "/assets/img-archiv/volta_by_meyoko-melissamurillo.jpg",
    altDrawingScreen: "images of meyoko's volta from  The Debut's Collection",

    //
    //  ---- LINK to shop
    // urlToProduct: "https://meyoko-creative.company.site/",
    urlToShop: "https://meyoko-creative-store.company.site/",
    //
    //  ---- COLLECTION
    // Not to be seen on the main FOLIO page (just on the tags )
    collection: "allDrawingsFolio",
    //
    //
    //       Title color
    headlineTitleLgTop: "#111111",
    svgSymbolColor: "#111111",
    //-----------
    //        BG & font 🔶
    backgroundColor: "#fff0c0", // yellow
    // #f9d3c72f  BG powder pink, this color has a transparency that mixed with the yellow bg of the MAIN, creates a nice shade of pink
    backgroundColor2: "#fff0c0", // yellow
    lgTitle: "#111111",
    smTitle: "#111111",
    paragraphDescription: "#0c0c0c",
    // -----------
    // -----------
    // BTN'S colors
    bgButton: "#0d0d0d",
    bgHoverButton: "transparent",
    colorFontButton: "#000000",
    colorHoverFontButton: "#f1e5cfd6",
    // btn small icon (attached to the btn)
    backgroundSMBtnAttached: "#fdfbf3",
    //
    //
    // **
    // categoryFolioGallery: "folio-picked-drawings",
    // **  CATEGORIES
    // all press
    brand: "allDrawings",
    // category: "home-screen-gallery-noCollection",
    // category: "drawings",
    // brand: "alecto",
    allCategories: "allDrawings",
    // related posts
    relatedPost: "editorial",
    // relatedPosts: [
    //   {
    //     id: 0,
    //     label: "editorial",
    //     imgRelPost: "",
    //   },
    // ],
    // ** TAGS 🟩
    tags: [
      {
        id: 0,
        label: "drawing",
      },
      // {
      //   id: 1,
      //   label: "graphite",
      // },
      {
        id: 2,
        label: "Oblivion",
      },
    ],
  },
  //
  //
  //
  //
  //-----------
  // ** PATTERNS
  //-----------
  //
  {
    _id: "23",
    extraUrlName: "argus-pattern",
    title: "Argus",
    subTitle: "Argus | pattern",
    spanTitle: "collection",
    artCreationDate: "2024",
    // ** DIMENSIONS & MATERIALS
    // height: 59,
    // width: 84,
    materials: "ink, acrylic",
    // ** DESC (p)
    descSpanUnderlinePattern0: "Argus,",
    descPattern1: "the legend of one hundred eyes, guarded",
    descSpanUnderlinePattern1: "'Io'",
    descPattern2: "until Hermes' played his lyre and slew the sleeping giant.",
    descSpanUnderlinePattern2: "His eyes were taken",
    descPattern3: "by Hera,",
    descSpanUnderlinePattern3: "to adorn the feathers",
    descPattern4: "of her divine beast, the peacock.",
    //
    //
    // ** IMAGES
    coverImg: "/assets/PRINTS/Argus-can-reimagined__00.jpg",
    // imgDraw1: "/assets/PRINTS/1_lilith-lg.jpg",
    // imgDraw2: "/assets/PRINTS/3_lilith-lg.jpg",
    // imgDraw3: "/assets/PRINTS/9_lilith-lg.jpg",
    // imgDraw4:  "/assets/PRINTS/ ",
    // ** LG img
    // imgDraw5Large: "/assets/PRINTS/15__lilith-lg.jpg",
    imgFixedBgDrawingScreen: "/assets/PRINTS/Argus-can-reimagined__03.jpg",
    altDrawingScreen: "images of meyoko's The Argus Collection",

    //
    //  ---- LINK to shop
    // urlToProduct: "https://meyoko-creative.company.site/",
    // urlToShop: "https://meyoko-creative-store.company.site/",
    //
    //  ---- COLLECTION
    collection: "allExperiments",
    imgsCollectPattern: [
      {
        id: 0,
        label: "mythology",
        imgDraw_1: "/assets/PRINTS/Argus-can-reimagined__01.jpg",
        altPattern_1: "image of can with meyokos design 'argos'",
      },
      {
        id: 1,
        label: "argus-pattern",
        imgDraw_1: "/assets/PRINTS/Argus-can-reimagined__02.jpg",
        altPattern_1: "image of can with meyokos design 'argos'",
      },
      // {
      //   id: 2,
      //   label: "packaging",
      //   imgDraw_1: "/assets/PRINTS/bottle_01.jpg",
      //   altPattern_1: "image of can with meyokos design 'argos'",
      // },
      // {
      //   id: 3,
      //   label: "packaging",
      //   imgDraw_1: "/assets/PRINTS/bottle_02.jpg",
      //   altPattern_1: "image of can with meyokos design 'argos'",
      // },
    ],
    //
    // **  CATEGORIES
    // all press
    brand: "allDrawings",
    category: "patterns-collection-argus",
    categories: "Allpatterns",
    // category: "drawings",
    // brand: "alecto",
    // category FOOTER (just for the footer of the pattern)
    patternFixedBtnFooterUrl: "",

    //😁 red once you will have at least 2 products with the same , lets say, you have the blume and the argos pattern each one having bottles , you will add on the related post label:'bottle', the reason for adding this tag here and not in the official tags, is not to mix drawings with merchandise
    // relatedPosts: [
    //   {
    //     id: 0,
    //     label: "editorial",
    //     imgRelPost: "",
    //   },
    // ],
    // ** TAGS 🟩
    tags: [
      {
        id: 0,
        label: "pattern",
      },
      {
        id: 1,
        label: "packaging",
      },
      {
        id: 2,
        label: "argus",
      },
    ],
  },
  // BLUME
  {
    _id: "24",
    extraUrlName: "blume-pattern",
    title: "Blüme",
    subTitle: "Blume | pattern",
    spanTitle: "collection",
    artCreationDate: "2024",
    // ** DIMENSIONS & MATERIALS
    // height: 59,
    // width: 84,
    materials: "ink, acrylic",
    // ** DESC (p)
    descSpanUnderlinePattern0: "Blüme,",
    descPattern1: "the pattern was inspired by",
    descSpanUnderlinePattern1: "'Adonis'.",
    descPattern2:
      "Adonis was a mortal man who possessed beauty that captivated mortals and goddesses alike. One day the young man went on a hunt, and in his fury, Ares sent upon him a wild boar whose tusks were sharpened to deadliness.",
    descSpanUnderlinePattern2:
      "Adonis didn't survive the encounter with the beast.",
    descPattern3: "So distraught and chagrined over his death,",
    descSpanUnderlinePattern3: "the Goddess wept over him",
    descPattern4:
      ", and from the depth of her loss, turned Adonis into a garden of Blüme.",
    //
    //
    // ** IMAGES
    coverImg: "/assets/PRINTS/07.jpg",
    // imgDraw1: "/assets/PRINTS/1_lilith-lg.jpg",
    // imgDraw2: "/assets/PRINTS/3_lilith-lg.jpg",
    // imgDraw3: "/assets/PRINTS/9_lilith-lg.jpg",
    // imgDraw4:  "/assets/PRINTS/ ",
    // ** LG img
    // imgDraw5Large: "/assets/PRINTS/15__lilith-lg.jpg",
    imgFixedBgDrawingScreen: "/assets/PRINTS/Argus-can-reimagined__03.jpg",
    altDrawingScreen: "images of meyoko's The Argus Collection",

    //
    //  ---- LINK to shop
    // urlToProduct: "https://meyoko-creative.company.site/",
    // urlToShop: "https://meyoko-creative.company.site/",
    //
    //  ---- COLLECTION
    collection: "allExperiments",
    imgsCollectPattern: [
      {
        id: 0,
        label: "mythology",
        imgDraw_1: "/assets/PRINTS/06.jpg",
        altPattern_1: "image of can with meyokos design 'argos'",
      },
      {
        id: 1,
        label: "argus-pattern",
        imgDraw_1: "/assets/PRINTS/11_blume-banane-flower.jpg",
        altPattern_1: "image of can with meyokos design 'argos'",
      },
    ],
    //
    // **  CATEGORIES
    // all press
    brand: "allDrawings",
    category: "patterns-collection-blume",
    categories: "Allpatterns",
    // category FOOTER (just for the footer of the pattern)
    patternFixedBtnFooterUrl: "",
    allCategories: "allDrawings",
    // related posts
    // relatedPosts: [
    //   {
    //     id: 0,
    //     label: "editorial",
    //     imgRelPost: "",
    //   },
    // ],
    // ** TAGS 🟩
    tags: [
      {
        id: 0,
        label: "pattern",
      },
      {
        id: 1,
        label: "packaging",
      },
      {
        id: 2,
        label: "blume",
      },
    ],
  },
];
export default dataAllMeyoko;
// beautiful-decay_00.jpg
