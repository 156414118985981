import React from "react";
import { NavLink } from "react-router-dom";
import "./stylesBtn-3d-effect.scss";

const Index = ({ pathUrlWithin, textButton3d }) => {
  return (
    <div className="wrapper-btn-3dEffect">
      <div className="btn-3dEffect">
        <NavLink to={pathUrlWithin}>
          <button>{textButton3d}</button>
        </NavLink>
      </div>
    </div>
  );
};

export default Index;
