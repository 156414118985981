import React from "react";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
//
//
import BounceBtnMinusX from "../AnimationProps/BounceBtn-minus-X";
import BounceXBtn from "../AnimationProps/Bouncing-X-btn";

import "./styles-footer-prints.scss";
import { motion } from "framer-motion";
//
const Index = () => {
  //
  //

  const location = useLocation();

  //
  //

  //
  //
  return (
    <div className="container-text-pattern-1-footer">
      {/*       3Xbox      🔷   👁️   🔷      */}
      <div className="headlineH1_3box_text-footer">
        <div className="headlineBlock_a">
          {/* dont change the ID from here below, to className, the svg will behave*/}
          <motion.div
            id="container-svg-footer-prints"
            // zoom img while drag
            drag
            whileDrag={{ scale: 1.4 }}
            dragConstraints={{
              top: -20,
              right: 20,
              bottom: 20,
              left: -20,
            }}
            // HOVER
            whileHover={{ scale: 1.1, color: "#3a3a3a" }}
            // ------ framer------
          >
            <BounceBtnMinusX>
              <NavLink
                to={
                  location.pathname === "/prints-collections"
                    ? "/prints-page-3"
                    : location.pathname === "/prints-page-3"
                    ? "/prints-page-2"
                    : location.pathname === "/prints-page-2"
                    ? "/prints-page-1"
                    : location.pathname === "/prints-page-1" &&
                      "/prints-collections"
                }
                className="svg-foo"
              >
                <svg
                  className="svg-box-footer-prints"
                  width="355"
                  height="131"
                  viewBox="0 0 355 131"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M80.9341 13C68.2674 30.3333 55.5636 43 3.93411 43M3.93411 43C-10.0659 43 137.934 43 137.934 126M3.93411 43H167.934C168.601 26 176.534 4.2 202.934 53C229.334 101.8 305.267 73.3333 339.934 53"
                    stroke="black"
                    stroke-width="5"
                  />
                  <circle
                    cx="15"
                    cy="15"
                    r="15"
                    transform="matrix(-1 0 0 1 354.934 39)"
                    fill="black"
                  />
                  <circle
                    cx="7.5"
                    cy="7.5"
                    r="7.5"
                    transform="matrix(-1 0 0 1 88.9341 0)"
                    fill="black"
                  />
                  <circle
                    cx="10"
                    cy="10"
                    r="10"
                    transform="matrix(-1 0 0 1 147.934 111)"
                    fill="#010101"
                  />
                </svg>{" "}
              </NavLink>
            </BounceBtnMinusX>
          </motion.div>
        </div>

        <NavLink to="/folio">
          <motion.div
            className="h1-box-3box"
            // zoom img while drag
            drag
            whileDrag={{ scale: 1.4 }}
            dragConstraints={{
              top: -20,
              right: 20,
              bottom: 20,
              left: -20,
            }}
          >
            <h1>Discover</h1>
          </motion.div>
        </NavLink>

        <div className="headlineBlock_b">
          <motion.div
            id="container-svg-footer-prints"
            // zoom img while drag
            // drag
            // whileDrag={{ scale: 1.4 }}
            // dragConstraints={{
            //   top: -20,
            //   right: 20,
            //   bottom: 20,
            //   left: -20,
            // }}
            // HOVER
            whileHover={{
              scale: 1.1,
              color: "#3a3a3a",
            }}
            // ------ framer------
          >
            <BounceXBtn>
              <NavLink
                to={
                  location.pathname === "/prints-collections"
                    ? "/prints-page-1"
                    : location.pathname === "/prints-page-1"
                    ? "/prints-page-2"
                    : location.pathname === "/prints-page-2"
                    ? "/prints-page-3"
                    : location.pathname === "/prints-page-3" &&
                      "/prints-collections"
                }
                // to="/prints-page-3"
                className="svg-foo"
              >
                <svg
                  className="svg-box-footer-prints"
                  width="355"
                  height="131"
                  viewBox="0 0 355 131"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M274 13C286.667 30.3333 299.371 43 351 43M351 43C365 43 217 43 217 126M351 43H187C186.333 26 178.4 4.2 152 53C125.6 101.8 49.6667 73.3333 15 53"
                    stroke="black"
                    stroke-width="5"
                  />
                  <circle cx="15" cy="54" r="15" fill="black" />
                  <circle cx="273.5" cy="7.5" r="7.5" fill="black" />
                  <circle cx="217" cy="121" r="10" fill="#010101" />
                </svg>
              </NavLink>
            </BounceXBtn>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Index;
