import { useContext } from "react";
import ColorChangeBgOnScrollContext from "../../Context";

import { HeadlineLg, ImgLgCardTar } from "../../componentHome";

import {
  HeadlineSectionWelcome,
  HeadlineLgCompProp,
} from "../../headlineComponents";

import {
  LargeImgComponent,
  AboutSectionComponent,
  BtnDot,
  HoverImgOnText,
  DividersSm,
} from "../../components";

import {
  imgLarge1Section,
  SemiotusPrint01,
  Anacridium2Print,
  FloraPrint,
  ImgTopTaro,
} from "../../headlineComponents/indexImg";

import Section1 from "./Section_01";
import Section2 from "./Section_02";

import "./Styles-Home.scss";
import "./styles-homeSticky-sections.scss";

const HomePage = () => {
  const { color, activeSection } = useContext(ColorChangeBgOnScrollContext);

  return (
    <>
      <div id="main-container" className={`${color}`}>
        <div className="container-components">
          <div id="section-1">
            <HeadlineLg
              titleHeadlineWelcH1=".Welcome."
              subTitleHeadlineWelcH2="To Meyoko"
            />
            <LargeImgComponent
              activeSection={activeSection}
              img={imgLarge1Section}
            />
            <DividersSm />
            <ImgLgCardTar
              imgTaroMiddle={ImgTopTaro}
              titleTopGallery="Gallery"
              symbolMiddleBottomDescript="Meyoko,also known as Melissa Murillo, an artist who invites viewers into captivating narratives through meticulously crafted scenes. Check the Selection"
              redirectPath="/prints-collections"
            />
          </div>
          {/*


           SECTION 1 & 2 ,
           the BG will change to BLACK


           */}
          <div id="section-2">
            <AboutSectionComponent
              // activeSection prop ,related to the color depending of the section
              // -----
              activeSection={activeSection}
              // -----
              titleh1line1="Welcome "
              titleh1line2="to Meyoko's "
              titleh1line3="official store"
              paragraph1="We offer a curated selection of high-quality prints,meticulously reproduced to capture the full depth of Meyoko's artistic vision"
              buttonText="Read More"
              imgAboutSectionComponentProp={FloraPrint}
            />
          </div>
          {/*


          SECTION  3,
          the BG will change to BLACK


          */}
          <div id="section-3">
            <HeadlineSectionWelcome
              // 🟥 activeSection prop ,related to the color depending of the section
              activeSection={activeSection}
              //
              titleCompWelcomeProp="Memento"
              subTitleCompWelcomeProp="Mori."
              titleCollectionsmall="Collection"
              subTitleCollectionsmall="memento"
            />
            <LargeImgComponent
              activeSection={activeSection}
              img={SemiotusPrint01}
            />

            {/*
            BTN dot
            */}
            <BtnDot
              // -------------- FURIES
              // 🔴 dont use the activeSection if you havent nested the other components in section-1
              activeSection={activeSection}
              titleHeadline_h1_textArrowBtn="selected works"
              titleHeadline_h2_textArrowBtn="Discover The Collection"
              redirectPath="/memento-mori"
            />
            <DividersSm />
            <DividersSm />
            <AboutSectionComponent
              // activeSection prop ,related to the color depending of the section
              // -----
              activeSection={activeSection}
              // -----

              titleh1line1="Behind each"
              titleh1line2="pattern lies a"
              titleh1line3="unique story"
              paragraph1="This section offers a glimpse into Meyoko's creative playground, where she delights in imagining her artwork adorning a variety of elements."
              buttonText="Explore"
              imgAboutSectionComponentProp={Anacridium2Print}
              redirectPath="/patterns-collections"
            />

            <HeadlineLgCompProp
              // prop with condition depending on section-2 black div
              // 🔴 dont use the activeSection if you havent nested the other components in section-1
              activeSection={activeSection}
              // --------------
              titleHeadlineCompProp="series"
              subTitleHeadlineCompProp="Archive"
              // spanSubTitleHeadlineCompProp="jects"
              titleHeadlineCompPropDate="selected works"
              spanHeadlineCompPropDate="2008 | 15"
            />
            <DividersSm />
            <HoverImgOnText />
            {/*  */}
          </div>
          {/* 🍥 STICKY SECTIONS  */}
        </div>
        <div className="home_sticky-container">
          <Section1 activeSection={activeSection} />
          <Section2 activeSection={activeSection} />
        </div>{" "}
      </div>
    </>
  );
};

export default HomePage;
