import { useEffect } from "react";

import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

import Aos from "aos";
import "aos/dist/aos.css";

const BouncingSoftBtn = ({ children }) => {
  const { ref, inView } = useInView();
  const animationOnviewBounceBtn = useAnimation();

  useEffect(() => {
    if (inView) {
      animationOnviewBounceBtn.start({
        //   y: 0,
        //   delayChildren: 0.3,
        //   staggerChildren: 0.2,
        //   opacity: 1,
        //   scale: 1,
        //   transition: {
        //     y: {
        //       type: "spring",
        //       damping: 9,
        //     },
        //   },
        //

        y: 0,
        delayChildren: 0.3,
        staggerChildren: 0.2,
        opacity: 1,
        scale: 1,
        transition: {
          y: {
            type: "spring",
            damping: 20, // Increase damping for smoother motion
            stiffness: 50, // Decrease stiffness for smoother motion
            duration: 2, // Increase duration for a slower animation
          },
        },
      });
    }

    if (!inView) {
      animationOnviewBounceBtn.start({
        y: 300,
        opacity: 0,
        transition: {
          y: {
            // stiffness: 4000
            // damping: 18,
          },
        },
      });
    }
    //
  }, [inView, animationOnviewBounceBtn]);

  //
  //
  // ------ AOS -----
  useEffect(() => {
    Aos.init({ duration: 800 });
  }, []);
  //
  //

  return (
    <>
      <motion.div
        // ---- framer
        ref={ref}
        // ---- framer
      >
        <motion.div animate={animationOnviewBounceBtn}> {children}</motion.div>
      </motion.div>
    </>
  );
};

export default BouncingSoftBtn;
