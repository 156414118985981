//
//  ** ---- IMG
import { AboutImg2 } from "../../headlineComponents/indexImg";
//
// ** ------ OTHER COMPONENTS
import { ArchComponent } from "../../headlineComponents";
//  ------- STYLES
import "./style-about.scss";
//  -------

//
//
const About = () => {
  // ------ framer -----------

  //
  //
  return (
    <section id="about-page">
      <ArchComponent
        titlePatt="The Motto"
        subTitlePatt1="'Beauty"
        subTitlePatt2="and Dreams'"
        imgArch={AboutImg2}
        // left & right text (cote a cote of the middle img)
        h1MiddleTextLeft="Ethereal grace, gentle kindness, serenity"
        h2MiddleTextRight=" Every unfolding moment fuels my creativity"
        //
        //
        // description of the component
        h1TitleArchDesc="About"
        h2TitleArchDesc="Melissa Murillo"
        spanTitleArchDesc="aka Meyoko"
        // span within the P tag
        spanArchDesc1="Meyoko,"
        pTitleArchDesc="also known as Melissa Murillo, creates the metamorphosis of a surreal world inhabited with hybrid characters and peculiar fauna."
        spanArchDesc2="She uses ballpoint pen,watercolor and acrylic to create luxuriant forest populated with imaginary plants and bizarre characters."
        pTitleArchDesc1="Her work often incorporates emotions such as melancholia, vulnerability, the fear of abandonment yet she confronts them with dogmas such as utopianism, heroism or humanism to achieve a narrative dialogue of intense poetry."
        spanArchDesc3="Meyoko, from Equatorial origins, discovered her passion for her art in France; she subsequently studied in Paris and began illustrating."
        pTitleArchDesc2="Turbulent times caused her to move to Berlin, in an environment more suitable for Meyoko's development and personal quest, a path devoted to her art."
        spanArchDesc4="Executed in free hand, with a fountain pen and black, China ink or more recently with Gold, the artworks by MEYOKO are like secret open doorways to a microcosmic wilderness populated by divine entities and mythical creatures"
        pTitleArchDesc3="Each intermingled and amalgamated to one another until they ultimately create hybrid shapes that are partly botanical partly animal. Dense Forests, made of luxuriant hair like roots are adorned with tattooed flowers and surreal plants or inhabited by totemic ravens and hummingbirds with strange silky bodies in place of feathers."
      />
    </section>
  );
};

export default About;
