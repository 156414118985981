import React from "react";
import { NavLink } from "react-router-dom";
//  ----- FRAMER
import { motion } from "framer-motion";
import ScaleInTransitionPage from "../../components/AnimationProps/ScaleIN-transition-enter-page.jsx";
// DATA
import allMeyoko from "../../data__AllMeyoko.js";
//
// ** STYLES
import "./styles-galleryAll.scss";

/*


*/

const Index = () => {
  return (
    <div className="wrapper_galleryAllDrawingsFolio">
      {/* the gallery */}
      <div className="gallery-container-lg">
        <div className="gallery-lg-card-container">
          {allMeyoko
            .filter(
              (product) =>
                product.categoryFolioGallery === "folio-picked-drawings"
            )
            .map((itemFolio) => (
              <ul key={`${itemFolio._id}`} className="card-gallery-lg">
                <li className="img-container-gallery-lg">
                  <NavLink
                    style={{ cursor: "none" }}
                    to={`/folio-drawing/${itemFolio._id}/${itemFolio.extraUrlName}`}
                  >
                    <div className="img-box-gallery-lg">
                      <ScaleInTransitionPage>
                        <motion.img
                          src={itemFolio.imgTopUnframed}
                          alt={itemFolio.altDrawingScreen}
                          whileHover={{
                            scale: 1.03,
                            transition: {
                              type: "spring",
                              duration: 1,
                              damping: 23,
                              delay: 0.08,
                            },
                          }}
                        />
                      </ScaleInTransitionPage>
                    </div>
                  </NavLink>
                </li>
              </ul>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Index;
