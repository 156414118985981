import React from "react";
import "./HeadlinePatternOpt2.scss";

const Index = ({
  titlePatt,
  subTitlePatt1,
  subTitlePatt2,
  bgColorSymbol,
  colorSymbol,
}) => {
  return (
    <div className="wrapper-HeadlineCollectionOption2">
      <div className="container-HeadlineCollectionOption2">
        {titlePatt && (
          <h1
            style={{
              backgroundColor: `${bgColorSymbol}`,
              color: `${colorSymbol}`,
            }}
          >
            {titlePatt}
          </h1>
        )}

        {subTitlePatt1 && (
          <h2>
            {subTitlePatt1}
            <span className="underline_H2-pattern_HeadlineCollectionOption2">
              {subTitlePatt2 && subTitlePatt2}
            </span>
          </h2>
        )}
      </div>
    </div>
  );
};

export default Index;
