import React from "react";
import { Btn3dEffect, DividersSm, GalleryLgItems } from "../../components";

//
import "./styles_allPrints-2v.scss";

const Index = () => {
  return (
    <section id="wrapper-allPrint-2version">
      <div className="headlineH1Box">
        <h1 className="headlineh1_Taro">.Gallery.</h1>
      </div>
      <GalleryLgItems /> <DividersSm />
      {/* <TriptichArch
        bgFarbeOpt1="#fff7de9f"
        descArch1="description ONe"
        descArch2="description Two"
        descArch3="description Tres"
      /> */}
      <Btn3dEffect textButton3d="See More" pathUrlWithin="/prints-page-2" />
      {/*

       */}
    </section>
  );
};

export default Index;
